import { audioURL, axiosApi, imageFromURL, text2Speech, uploadFile, uploadVideo, videoFromURL } from 'helpers/api_helper';
import React, { useEffect, useState } from 'react';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';

const formInput = {
  marginTop: "0.5rem",
};

const SelectInput = (props) => {
  const {
    name,
    label,
    value,
    required=false,
    onChange=()=>{},
    options=[],
    optionKey="_id",
    optionValue="name",
    isObject=false,
    style={},
  } = props;

  return (
    <div className="form-group row mb-2">
      <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
      <div className="col-sm-11" style={formInput}>
        <Input
          name={name}
          className="form-control"
          type="select"
          required={required}
          value={value}
          onChange={onChange}
        >
          {isObject && Object.entries(options).map(([key, value]) => (
            <option key={name+"-"+key} value={key}>{value}</option>
          ))}
          {!isObject && options.map((option, index) => (
            <option key={name+"-"+index} value={option[optionKey]}>{option[optionValue]}</option>
          ))}
        </Input>
      </div>
    </div>
  );
};

const SelectInputCol = props => {
  const {
    name,
    label,
    value,
    required=false,
    onChange=()=>{},
    options=[],
    optionKey="_id",
    optionValue="name",
    isObject=false,
  } = props;

  return (
    <Col>
      <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
      <div className="col-sm-11" style={formInput}>
        <Input
          name={name}
          id={name}
          className="form-control"
          type="select"
          value={value}
          required={required}
          onChange={onChange}
        >
          {isObject && Object.entries(options).map(([key, value]) => (
            <option key={name+"-"+key} value={key}>{value}</option>
          ))}
          {!isObject && options.map((option, index) => (
            <option key={name+"-"+index} value={option[optionKey]}>{option[optionValue]}</option>
          ))}
        </Input>
      </div>
    </Col>
  );
};

const SelectInputColIndex = props => {
  const {
    name,
    label,
    value,
    required=false,
    onChange=()=>{},
    options=[],
  } = props;

  return (
    <Col>
      <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
      <div className="col-sm-11" style={formInput}>
        <Input
          name={name}
          id={name}
          className="form-control"
          type="select"
          value={value}
          required={required}
          onChange={onChange}
        >
          {options.map((option, index) => (
            <option key={name+"-"+index} value={index}>{index + 1}</option>
          ))}
        </Input>
      </div>
    </Col>
  );
};

const NumberInput = (props) => {
  const {
    name,
    label,
    value,
    required=false,
    onChange=()=>{},
  } = props;

  return (
    <div className="form-group row mb-2">
      {label !== false && (
      <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
      )}
      <div className="col-sm-11" style={formInput}>
        <Input
          name={name}
          className="form-control"
          type="number"
          required={required}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const TextInput = (props) => {
  const {
    name,
    label,
    value,
    required=false,
    onChange=()=>{},
  } = props;

  return (
    <div className="form-group row mb-2">
      <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
      <div className="col-sm-11" style={formInput}>
        <Input
          name={name}
          className="form-control"
          type="text"
          required={required}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const TextareaInput = (props) => {
  const {
    name,
    label,
    value,
    required=false,
    onChange=()=>{},
  } = props;

  return (
    <div className="form-group row mb-2">
      <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
      <div className="col-sm-11" style={formInput}>
        <Input
          name={name}
          className="form-control"
          type="textarea"
          required={required}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

const DateInput = (props) => {
  const {
    name,
    label,
    value,
    required=false,
    onChange=()=>{},
  } = props;

  return (
    <div className="form-group row mb-2">
      <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
      <div className="col-sm-11" style={formInput}>
        <Input
          name={name}
          className="form-control"
          type="date"
          required={required}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const CheckboxInput = (props) => {
  const {
    name,
    label,
    value,
    required=false,
    onChange=()=>{},
  } = props;

  return (
    <div className="form-group row mb-2">
      <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
      <div className="col-sm-11" style={formInput}>
        <Input
          name={name}
          className="form-control"
          type="checkbox"
          required={required}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const FileInput = (props) => {
  const {
    name,
    label,
    value,
    required=false,
    onChange=()=>{},
    labelColSize=1,
    inputColSize=11,
    target=false,
  } = props;

  return (
    <div className="form-group row mb-2">
      <Label for={name} className={`col-sm-${labelColSize} col-form-label`}>{label}</Label>
      <div className={`col-sm-${inputColSize}`} style={formInput}>
        {target ? (
        <Input
          name={name}
          className="form-control"
          type="file"
          required={required}
          target={target}
          onChange={onChange}
        />
        ) : (
        <Input
          name={name}
          className="form-control"
          type="file"
          required={required}
          value={value}
          onChange={onChange}
        />
        )}
      </div>
    </div>
  );
};

const FileInputInRow = (props) => {
  const {
    name,
    label,
    required=false,
    useStateFile,
    setStateFile,
  } = props;

  return (
    <Row>
      <Col>
        <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
        <Row>
          <div className="col-sm-9" style={formInput}>
            <Input
              name={name}
              id={name}
              className="form-control"
              type="file"
              target={useStateFile ? useStateFile : null}
              onChange={(e) => setStateFile(e.target)}
            />
          </div>
        </Row>
      </Col>
    </Row>
  );
};

const ImageInput = (props) => {
  const {
    name,
    label,
    useStateFileId,
    setStateFileId,
    setStateFn=false,
    alt="",
  } = props;

  const [usePicture, setPicture] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");

  useEffect(() => {
    if(usePicture.type) {
      const file = usePicture.files[0];
      if(file && file != "") {
        uploadFile(file).then(response => {
          if(setStateFn) setStateFn(response._id);
          else setStateFileId(response._id);
          const objectUrl = URL.createObjectURL(file);
          setPreviewUrl(objectUrl);
        });
      }
    }
  }, [usePicture]);

  return (
    <Row>
      <Col>
        <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
        <Row>
          <div className="col-sm-9" style={formInput}>
            <Input
              name={name}
              id={name}
              className="form-control"
              type="file"
              target={usePicture ? usePicture : null}
              onChange={(e) => setPicture(e.target)}
            />
          </div>
          <div className="col-sm-2">
          {previewUrl && (
              <>
                {previewUrl.endsWith(".svg") ? (
                  <object
                    data={previewUrl}
                    type="image/svg+xml"
                    style={{ height: "50px", width: "auto" }}
                  >
                    <img
                      src={previewUrl}
                      alt={alt}
                      style={{ height: "50px", width: "auto" }}
                    />
                  </object>
                ) : (
                  <img
                    src={previewUrl}
                    alt={alt}
                    style={{ height: "50px", width: "auto" }}
                  />
                )}
              </>
            )}
          </div>
        </Row>
      </Col>
    </Row>
  );
};

const VideoInput = (props) => {
  const {
    name,
    label,
    fileId=false,
    useStateFileId,
    setStateFileId,
    setStateFn=false,
    alt="",
    labelColSize=1,
    inputColSize=9,
  } = props;

  const [useVideo, setVideo] = useState("");
  const [useIsUploading, setIsUploading] = useState(false);
  const [useUploadCompleted, setUploadCompleted] = useState(false);
  const [useUploadProgress, setUploadProgress] = useState(0);
  const [useRemoteVideoURL, setRemoteVideoURL] = useState("");

  useEffect(() => {
    if(fileId) {
      axiosApi.get(`/file/${fileId}`).then(response => {
        setRemoteVideoURL(response?.data?.remotes[0]?.url);
      });
    }
  }, []);

  useEffect(() => {
    if(useVideo.type) {
      const file = useVideo.files[0];
      if(file && file != "") {
        setIsUploading(true);
        uploadVideo(file).then(response => {
          const fileId = response._id;
          if(setStateFn) setStateFn(response._id);
          else setStateFileId(response._id);
          return fileId;
        }).then(fileId => {
          const uploadProgressInteval = setInterval(() => {
            axiosApi.get(`/file/remoteProgress/${fileId}`).then(response => {
              setUploadProgress(response?.data?.progress);
              if(response?.data?.progress == 100) {
                setIsUploading(false);
                setUploadCompleted(true);
                clearInterval(uploadProgressInteval);
                axiosApi.get(`/file/${fileId}`).then(response => {
                  setRemoteVideoURL(response?.data?.remotes[0]?.url);
                });
              }
            });
          }, 2000)
        });
      }
    }
  }, [useVideo]);

  return (
    <Row>
      <Col>
        <Row>
        <Label for={name} className={`col-sm-${labelColSize} col-form-label`}>{label}</Label>
        <div className={`col-sm-${inputColSize}`}>
          <Row>
            <div className={`col-sm-12`} style={formInput}>
              <Input
                name={name}
                id={name}
                className="form-control"
                type="file"
                target={useVideo ? useVideo : null}
                onChange={(e) => setVideo(e.target)}
              />
            </div>
            <div className="col-sm-6" style={{marginTop:"10px"}}>
              {useIsUploading && !useUploadCompleted ? (
                <Spinner color="primary" />
              ): useUploadCompleted ? (
                <i className="mdi mdi-check-circle" style={{color:"green", fontSize: "3rem"}}></i>
              ) : <></>}
              {fileId && fileId != "" && (
                <iframe src={useRemoteVideoURL.replace('/play/','/embed/')} style={{width:"400px", height:"200px"}}></iframe>
              )}
            </div>
          </Row>
        </div>
        </Row>
      </Col>
    </Row>
  );
};

const Text2SpeechInput = props => {
  const {
    name,
    label,
    language="en",
    useStateFileId,
    setStateFileId,
    setStateFn=false,
  } = props;

  const [useVoiceText, setVoiceText] = useState("");

  const handleVoiceText = () => {
    if(useVoiceText && useVoiceText != "") {
      text2Speech(useVoiceText, {language}).then(response => {
        if(setStateFn) setStateFn(response?.id);
        else setStateFileId(response?.id);
      });
    }
  };

  return (
    <Row>
      <Col>
        <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
        <div className="col-sm-11" style={formInput}>
          <Row>
            <div className="col-sm-5">
              <Input
                name={name}
                id={name}
                className="form-control"
                type="text"
                placeholder="Seslendirilecek Metin"
                value={useVoiceText}
                onChange={(e) => setVoiceText(e.target.value)}
              />
            </div>
            <div className="col-sm-1">
              <Button color="primary" onClick={handleVoiceText}>Seslendir</Button>
            </div>
            {useStateFileId && useStateFileId != "" && (
              <div className="col-sm-2">
                <audio
                  controls
                  src={audioURL(useStateFileId)}
                  style={{height: "40px"}}
                />
              </div>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};

const TextInputInRow = props => {
  const {
    name,
    label,
    required=false,
    useState,
    setState,
  } = props;

  return (
    <Row>
      <Col>
        <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
        <div className="col-sm-11" style={formInput}>
          <Input
            name={name}
            className="form-control"
            type="text"
            required={required}
            value={useState ? useState : ""}
            onChange={(e) => setState(e.target.value)}
          />
        </div>
      </Col>
    </Row>
  );
};

const TextareaInputInRow = props => {
  const {
    name,
    label,
    required=false,
    useState,
    setState,
  } = props;

  return (
    <Row>
      <Col>
        <Label for={name} className="col-sm-1 col-form-label">{label}</Label>
        <div className="col-sm-11" style={formInput}>
          <Input
            name={name}
            id={name}
            className="form-control"
            type="textarea"
            required={required}
            style={{height: "200px"}}
            value={useState ? useState : ""}
            onChange={(e) => setState(e.target.value)}
          />
        </div>
      </Col>
    </Row>
  );
}

const InlineCheckboxInput = props => {
  const {
    name,
    label,
    value,
    onChange=()=>{},
  } = props;

  return (
    <FormGroup check inline>
      <Input
        id={name}
        name={name}
        className="form-control checkbox-input"
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <Label for={name} className="col-form-label checkbox-label">{label}</Label>
    </FormGroup>
  );
};

const AudioRecorderInput = props => {
  const {
    name,
    label,
    useFileId,
    setFileId,
  } = props;

  const addAudioElement = (blob) => {
    const audioFile = new File([blob], "audio.mp3", { type: "audio/mp3" });
    (async () => {
      const response = await uploadFile(audioFile);
      setFileId(response._id);
    })();
  };

  return (
    <div className="form-group row mb-2">
      <Label for={name} className="col-sm-3 col-form-label">{label}</Label>
      <div className="col-sm-4" style={formInput}>
        <AudioRecorder
          onRecordingComplete={addAudioElement}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          onNotAllowedOrFound={(err) => console.table(err)}
          downloadFileExtension="mp3"
          mediaRecorderOptions={{
            audioBitsPerSecond: 128000,
          }}
        />
      </div>
      {useFileId && useFileId != "" && (
        <div className="col-sm-5">
          <audio
            controls
            src={audioURL(useFileId)}
            style={{height: "40px"}}
          />
        </div>
      )}
    </div>
  );
};

const ColorInput = props => {
  const {
    name,
    label,
    value,
    onChange=()=>{},
    removeFn=()=>{},
    readOnly=false,
  } = props;

  let style = {width:"100px", height:"100px", margin:"0 auto"};
  if(readOnly) style = {width: "100px", height: "100px", margin: "0 auto"};

  return (
    <FormGroup style={{marginTop:"10px", textAlign: "center"}}>
      <Input
        id={name}
        name={name}
        className="form-control"
        type="color"
        style={style}
        value={value}
        onChange={onChange}
        readOnly={!readOnly}
      />
      {!readOnly && (
      <>
      <Label for={name} className="col-form-label">{label}</Label>
      <Button style={{marginLeft:"5px"}} color="danger" size="sm" 
        onClick={removeFn}
      >
        <i className="mdi mdi-trash-can"></i>
      </Button> 
      </>
      )}
    </FormGroup>
  );
};

export { 
  SelectInput, 
  NumberInput, 
  TextInput, 
  TextareaInput, 
  DateInput, 
  CheckboxInput, 
  FileInput, 
  ImageInput, 
  Text2SpeechInput, 
  TextInputInRow, 
  TextareaInputInRow, 
  InlineCheckboxInput, 
  AudioRecorderInput, 
  ColorInput,
  SelectInputCol,
  SelectInputColIndex,
  FileInputInRow,
  VideoInput,
};