import React, { useEffect, useState} from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import DataTable from "components/Custom/DataTable";
import { HTTP, imageFromURL, uploadFile, uploadVideo, Request, audioURL, text2Speech, text2SpeechTR, uploadFileToMP3 } from "helpers/api_helper";
import { Badge, Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Progress, Row, Spinner, Tooltip } from "reactstrap";
import XModal from "components/Custom/XModal";
import { slugify } from "helpers/helpers";
import ActionsCol from "components/Custom/ActionsCol";
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { filter } from "lodash";
import { badger } from "components/Custom/XBadge";
import X from "components/Custom/X";
import MainAlert from "components/Custom/MainAlert";
import * as moment from "moment";
import { AudioRecorder } from "react-audio-voice-recorder";
import { useAlert } from "providers/alertProvider";

const dataCols = [
  {
    dataField: "name",
    text: "Başlık",
    type: "text",
    required: true,
    filter: textFilter(),
  },
  {
    dataField: "dateTime",
    text: "Tarih ve Zaman",
    type: "date",
    required: true,
  },
  {
    dataField: "recurrence",
    text: "Tekrar ediyor mu?",
    type: "text",
    required: false,
  },
  {
    dataField: "recurrencePeriod",
    text: "Tekrar periyodu",
    type: "text",
    required: false,
  },
  {
    dataField: "recurrenceEndDate",
    text: "Tekrarın son tarihi?",
    type: "date",
    required: false,
  },
  {
    dataField: "voiceFile",
    text: "Ses",
    type: "file",
    required: false,
  },
  {
    dataField: "customVoiceFile",
    text: "Özel Ses Dosyası",
    type: "file",
    required: false,
  },
  
];

const cols = [
  {
    dataField: "num",
    text: "#",
    type: false
  },
  ...dataCols,
  {
    dataField: "actions",
    text: "İşlemler",
    type: false,
  }
];

const formInput = {
  marginTop: "0.5rem",
};

const recurrencePeriods = {
  "daily": "Günlük",
  "weekly": "Haftalık",
  "monthly": "Aylık",
  "yearly": "Yıllık",
};

const url = "reminder";
const pageTitle = "Hatırlatıcılar";
const modelSingularName = "Hatırlatıcı";
const modelPluralName = "Hatırlatıcılar";
const breadcrumbItems = [
  { title: "Anasayfa", url: "/" },
  { title: "Hatırlatıcılar", url: "/hatirlaticilar" },
];

const Reminders = props => {
  document.title = props.title || "Hatırlatıcılar";
  useEffect(() => {
    props.onSetBreadCrumbs(modelPluralName, breadcrumbItems);
  }, []);

  //! Core Codes
  const [useDataTableData, setDataTableData] = useState([]);
  const [useTableData, setTableData] = useState([]);
  const [useViewModal, setViewModal] = useState(false);
  const [useEditModal, setEditModal] = useState(false);
  const [useDeleteModal, setDeleteModal] = useState(false);
  const [useNewModal, setNewModal] = useState(false);
  const [useMultiModal, setMultiModal] = useState(false);
  const [useActiveViewItem, setActiveViewItem] = useState({});
  const [useActiveEditItem, setActiveEditItem] = useState({});
  const [useActiveDeleteItem, setActiveDeleteItem] = useState({});
  const [useActiveEditItemName, setActiveEditItemName] = useState('');
  const [useActiveNewItem, setActiveNewItem] = useState({});
  const [useCols, setCols] = useState(cols);
  const [useAllResponse, setAllResponse] = useState({});
  const [useVoiceText, setVoiceText] = useState("");
  const [useVoiceURL, setVoiceURL] = useState("");
  const [useVoiceId, setVoiceId] = useState("");
  const [useVoiceTextEdit, setVoiceTextEdit] = useState("");
  const [useVoiceURLEdit, setVoiceURLEdit] = useState("");
  const [useVoiceIdEdit, setVoiceIdEdit] = useState("");
  const [useCustomVoiceURL, setCustomVoiceURL] = useState("");
  const [useCustomVoiceId, setCustomVoiceId] = useState("");
  const [useCustomVoiceURLEdit, setCustomVoiceURLEdit] = useState("");
  const [useCustomVoiceIdEdit, setCustomVoiceIdEdit] = useState("");
  const [openAlert, closeAlert] = useAlert();
  const [useErrorFields, setErrorFields] = useState([]);

  const toggleViewModal = (item) => {
    setViewModal(!useViewModal);
    item ? setActiveViewItem(item) : setActiveViewItem({});
  };
  const toggleEditModal = (item) => {
    if(item) {
      setErrorFields([]);
      setActiveEditItem(item);
      setActiveEditItemName(item.title);
      setEditModal(true);
    } else {
      setEditModal(false);
    }
  }
  const toggleDeleteModal = (item) => {
    if(item) {
      setActiveDeleteItem(item);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
    }
  }
  const toggleNewModal = () => {
    setErrorFields([]);
    setNewModal(!useNewModal);
  }
  const toggleMultiModal = () => {
    setErrorFields([]);
    setMultiModal(!useMultiModal);
  }
  
  const validateFields = (activeItem) => {
    const errors = []; 
    for (let dataCol of dataCols) {
        if (dataCol.required && !activeItem[dataCol.dataField]) {
          errors.push(dataCol.dataField);
        }
    }
    setErrorFields(errors); 
    return errors.length === 0;  
  };

  const handleEditItemChange = (e) => {
    if(e.target.type === "file") {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target,
      });
    } else {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleNewItemChange = (e) => {
    let value = e.target;
    if(e.target.type !== "file") {
      value = value.value
    } 
    if(e.target.type === "checkbox") {
      value = e.target.checked;
    }

    setActiveNewItem({
      ...useActiveNewItem,
      [e.target.name]: value,
    });
  };

  const handleNewVoiceText = () => {
    if(useVoiceText && useVoiceText != "") {
      text2SpeechTR(useVoiceText).then(response => {
        setVoiceURL(response.url);
        setVoiceId(response?.id);
      });
    }
  };

  const handleEditVoiceText = () => {
    if(useVoiceTextEdit && useVoiceTextEdit != "") {
      text2SpeechTR(useVoiceTextEdit).then(response => {
        setVoiceURLEdit(response.url);
        setVoiceIdEdit(response?.id);
      });
    }
  };

  
  const handleEdit = (activeItem) => {
    if (!validateFields(activeItem)) {
      return;
    }
    // Check if the image typed fields in the form is set
    const deletedFields = [];
    const fileUploadPromises = [];
    for (let dataCol of dataCols.filter((dataCol) => dataCol.isForeign !== true)) {
      if (dataCol.type === "image" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "image" && activeItem[dataCol.dataField]) {
        if(activeItem[dataCol.dataField]?.files?.length > 0) {
          const file = activeItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            activeItem[dataCol.dataField] = fileId;
          }));
        }
      }
      if (dataCol.type === "file" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "file" && activeItem[dataCol.dataField]) {
        if(activeItem[dataCol.dataField]?.files?.length > 0) {
          const file = activeItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            activeItem[dataCol.dataField] = fileId;
          }));
        }
      }
      if (dataCol.readonly && dataCol.dataField === "slug") {
        activeItem.slug = slugify(activeItem.name);
        delete activeItem.slug;
      }
    }
    if(useVoiceIdEdit && useVoiceIdEdit != "") {
      useActiveEditItem["voiceFile"] = useVoiceIdEdit;
    }
    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveEditItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveEditItem[key];
        }
      });
      Request.put(`/${url}/${activeItem?._id}`, activeItem)
      .then(response => {
        if (response) {
          openAlert(`${modelSingularName} güncellendi`);
        } else {
          openAlert("İşlem başarısız, lütfen tekrar deneyin.", "danger");
        }
    
        return Request.get(`/${url}/byMe`);
      })
      .then(response => response.data)
      .then(data => {
        fetchEntire();
      })
      .then(() => {
        toggleEditModal();
        reset();
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
    });
  };

  useEffect(() => {
    if (!useEditModal) {
      setActiveEditItem({});
    }
  }, [useEditModal]);

  const handleNew = () => {
    if (!validateFields(useActiveNewItem)) {
      return;
    }
    const fileUploadPromises = [];
    for (let dataCol of dataCols) {
      if (dataCol.type === "image" && useActiveNewItem[dataCol.dataField]) {
        const file = useActiveNewItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(file).then((response) => {
          const fileId = response._id;
          useActiveNewItem[dataCol.dataField] = fileId;
        }));
      } else if(dataCol.type === "file" && useActiveNewItem[dataCol.dataField]) {
        if(useActiveNewItem[dataCol.dataField]?.files?.length > 0) {
          const file = useActiveNewItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            useActiveNewItem[dataCol.dataField] = fileId;
          }));
        }
      }
    }
    if(useActiveNewItem["selfAudio"]) {
      useActiveNewItem["customVoiceFile"] = useCustomVoiceId;
    } else {
      useActiveNewItem["voiceFile"] = useVoiceId;
    }
    delete useActiveNewItem["selfAudio"];

    if(useActiveNewItem["recurrence"] && useActiveNewItem["recurrence"] !== false && useActiveNewItem["recurrence"] !== "false") {
      useActiveNewItem["recurrence"] = useActiveNewItem["recurrencePeriod"];
      delete useActiveNewItem["recurrencePeriod"];
    } else if(useActiveNewItem["recurrence"] == false || useActiveNewItem["recurrence"] == "false") {
      delete useActiveNewItem["recurrence"];
    }

    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveNewItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveNewItem[key];
        }
      });
      Request.post(`/${url}`, useActiveNewItem)
      .then(response => {
        if (response) {
          openAlert(`${modelSingularName} olusturuldu`);
        } else {
          openAlert("İşlem başarısız, lütfen tekrar deneyin.", "danger");
        }

        return Request.get(`/${url}/byMe`);
      })
      .then(response => response.data)
      .then(data => {
        fetchEntire();
      })
      .then(() => {
        toggleNewModal();
        setActiveNewItem({});
      })
      .then(() => {
        reset();
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
    });
  };

  const handleDelete = (activeItem) => {
    Request.del(`/${url}/${activeItem._id}`)
      .then(response => {
        if (response) {
          openAlert(`${modelSingularName} silindi`);
        } else {
          openAlert("İşlem başarısız, lütfen tekrar deneyin.", "danger");
        }
  
        return Request.get(`/${url}/byMe`);
      })
      .then(response => response.data)
      .then(data => {
        fetchEntire();
      })
      .then(() => {
        toggleDeleteModal();
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
  }; 

  const addAudioElement = (blob) => {
    const audioFile = new File([blob], "audio.mp3", { type: "audio/mp3" });
    (async () => {
      const response = await uploadFileToMP3(audioFile, {toMP3:true});
      setCustomVoiceId(response._id);
    })();
  };

  useEffect(() => {
    if (!useDeleteModal) {
      setActiveDeleteItem({});
    }
  }, [useDeleteModal]);

  const fetchTableData = (data) => {
    if(data &&  data.length > 0 && Array.isArray(data)) {
      const mapped = data.map((item, index) => {
        return {
          num: index + 1,
          name: item.name,
          dateTime: item.dateTime ? moment(item.dateTime).format("DD/MM/YYYY HH:mm:ss") : "",
          recurrence: item.recurrence ? <span style={{color:"#008c07"}}>Evet</span> : <span style={{color:"red"}}>Hayır</span>,
          recurrencePeriod: item.recurrence ? recurrencePeriods[item.recurrence] : "",
          recurrenceEndDate: item.recurrenceEndDate ? moment(item.recurrenceEndDate).format("DD/MM/YYYY HH:mm:ss") : "",
          voiceFile: item?.voiceFile?._id ? <audio controls><source src={audioURL(item?.voiceFile?._id)} type="audio/mpeg" /></audio> : "",
          customVoiceFile: item?.customVoiceFile?._id ? <audio controls><source src={audioURL(item?.customVoiceFile?._id)} type="audio/mpeg" /></audio> : "",
          actions: 
            <ActionsCol 
              id={item._id} 
              onView={()=>{toggleViewModal(item)}}
              onDelete={()=>{toggleDeleteModal(item)}}
            />,
        }
      });
      console.log("MAPPED", mapped);
      return mapped;
    }
    return [];
  };

  const fetchEntire = () => {
    Request.get(`/${url}/byMe`)
      .then(response => {
        const data = response.data;
        setDataTableData(data);
        setAllResponse(data);
        return data;
      })
      .then(data => {
        const fetchedTableData = fetchTableData(data);
        setTableData(fetchedTableData);
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
  };  

  const reset = async () => {
    setActiveNewItem({});
    setActiveEditItem({});
    setVoiceText("");
    setVoiceURL("");
    setVoiceId("");
    setVoiceTextEdit("");
    setVoiceURLEdit("");
    setVoiceIdEdit("");
    await fetchEntire();
  };

  useEffect(() => {
    fetchEntire();
  }, []);
  //! End Core Codes
  
  return (
    // <DataTable dataCols={dataCols} cols={cols} url={url} modelPluralName={modelPluralName} pageTitle={pageTitle} />
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {pageTitle}
                    <Button color="success" style={{marginLeft: "1rem"}} onClick={toggleNewModal}>
                      Yeni Ekle
                    </Button>
                  </CardTitle>
                  Toplam {useAllResponse?.length} {modelSingularName} bulunmaktadır.
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="num"
                      data={useTableData}
                      columns={useCols}
                      filter={filterFactory()}
                      filterPosition="inline"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      

      <XModal 
        title="Yeni Ekle" 
        showModal={useNewModal}
        toggle={toggleNewModal}
        onSubmit={()=>{handleNew();}}
        size="xl"
      >
        <form>

          <div className="form-group row mb-2">
            <Label for="name" className="col-sm-3 col-form-label">Başlık</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="name"
                id="name"
                className={`form-control ${useErrorFields.includes('name') ? 'is-invalid' : ''}`}
                type="text"
                required={true}
                value={useActiveNewItem["name"] ? useActiveNewItem["name"] : ""}
                onChange={handleNewItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          
          <div className="form-group row mb-2">
            <Label for="content" className="col-sm-3 col-form-label">İçerik</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="content"
                id="content"
                className="form-control"
                type="text"
                required={false}
                value={useActiveNewItem["content"] ? useActiveNewItem["content"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>

          <div className="form-group row mb-2">
            <Label for="description" className="col-sm-3 col-form-label">Açıklama</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="description"
                id="description"
                className="form-control"
                type="textarea"
                required={false}
                value={useActiveNewItem["description"] ? useActiveNewItem["description"] : ""}
                onChange={handleNewItemChange}
                style={{height:"100px"}}
              />
            </div>
          </div>

          <div className="form-group row mb-2">
            <Label for="dateTime" className="col-sm-3 col-form-label">Tarih ve Zaman</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="dateTime"
                id="dateTime"
                className={`form-control ${useErrorFields.includes('dateTime') ? 'is-invalid' : ''}`}
                type="datetime-local"
                required={false}
                value={useActiveNewItem["dateTime"] ? useActiveNewItem["dateTime"] : ""}
                onChange={handleNewItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>

          <div className="form-group row mb-2">
            <Label for="recurrence" className="col-sm-3 col-form-label">Tekrar ediyor mu?</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="recurrence"
                id="recurrence"
                className="form-control"
                type="checkbox"
                required={false}
                value={useActiveNewItem["recurrence"] != undefined ? useActiveNewItem["recurrence"] : ""}
                onChange={handleNewItemChange}
                style={{height:"1.5rem", width: "1.5rem"}}
              />
            </div>
          </div>

          <X xIf={useActiveNewItem["recurrence"]}>
            <div className="form-group row mb-2">
              <Label for="recurrencePeriod" className="col-sm-3 col-form-label">Tekrar Periyodu</Label>
              <div className="col-sm-9" style={formInput}>
                <Input
                  name="recurrencePeriod"
                  id="recurrencePeriod"
                  className="form-control"
                  type="select"
                  required={false}
                  value={useActiveNewItem["recurrencePeriod"] != undefined ? useActiveNewItem["recurrencePeriod"] : ""}
                  onChange={handleNewItemChange}
                >
                  {Object.entries(recurrencePeriods).map(([key, value]) => {
                    return <option key={key} value={key}>{value}</option>
                  })}
                </Input>
              </div>
            </div>
          </X>

          <X xIf={useActiveNewItem["recurrence"]}>
            <div className="form-group row mb-2">
              <Label for="recurrenceEndDate" className="col-sm-3 col-form-label">Tekrarın Sona Erdiği Tarih ve Zaman</Label>
              <div className="col-sm-9" style={formInput}>
                <Input
                  name="recurrenceEndDate"
                  id="recurrenceEndDate"
                  className="form-control"
                  type="datetime-local"
                  required={false}
                  value={useActiveNewItem["recurrenceEndDate"] ? useActiveNewItem["recurrenceEndDate"] : ""}
                  onChange={handleNewItemChange}
                />
              </div>
            </div>
          </X>

          <div className="form-group row mb-2">
            <Label for="selfAudio" className="col-sm-3 col-form-label">Ses Kaydını Kendim Yapacağım</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="selfAudio"
                id="selfAudio"
                className="form-control"
                type="checkbox"
                required={false}
                value={useActiveNewItem["selfAudio"] != undefined ? useActiveNewItem["selfAudio"] : ""}
                onChange={handleNewItemChange}
                style={{height:"1.5rem", width: "1.5rem"}}
              />
            </div>
          </div>
          <X xIf={!useActiveNewItem["selfAudio"]}>
          <div className="form-group row mb-2">
            <Label for="voiceFile" className="col-sm-3 col-form-label">Seslendirme</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="voiceFile"
                id="voiceFile"
                className="form-control"
                type="text"
                target={useActiveNewItem["voiceFile"] ? useActiveNewItem["voiceFile"] : ""}
                value={useVoiceText}
                onChange={e => setVoiceText(e.target.value)}
              />
            </div>
            <div className="col-sm-1">
              <Button color="primary" onClick={handleNewVoiceText}>Seslendir</Button>
            </div>
            {useVoiceURL && useVoiceURL != "" && (
              <div className="col-sm-2">
                <audio
                  controls
                  src={useVoiceURL}
                  style={{height: "40px"}}
                />
              </div>
            )}
          </div>
          </X>

          <X xIf={useActiveNewItem["selfAudio"]}>
          <div className="form-group row mb-2">
            <Label for="customVoiceFile" className="col-sm-3 col-form-label">Özel Ses Dosyası</Label>
            <div className="col-sm-4" style={formInput}>
              <AudioRecorder
                onRecordingComplete={addAudioElement}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                onNotAllowedOrFound={(err) => console.table(err)}
                downloadFileExtension="mp3"
                mediaRecorderOptions={{
                  audioBitsPerSecond: 128000,
                }}
              />
            </div>
            {useCustomVoiceId && useCustomVoiceId != "" && (
              <div className="col-sm-5">
                <audio
                  controls
                  src={audioURL(useCustomVoiceId)}
                  style={{height: "40px"}}
                />
              </div>
            )}
          </div>
          </X>





        </form>
      </XModal>
      <XModal title="Görüntüle" itemTitleValue={useActiveViewItem?.name} showModal={useViewModal} toggle={toggleViewModal} saveButton={false}>
    
        <div className="form-group row mb-2">
          <Label for="name" className="col-sm-3 col-form-label">Başlık</Label>
          <div className="col-sm-9">
            <Input id="name" className="form-control" type="text" value={useActiveViewItem["name"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="content" className="col-sm-3 col-form-label">İçerik</Label>
          <div className="col-sm-9">
            <Input id="content" className="form-control" type="text" value={useActiveViewItem["content"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="description" className="col-sm-3 col-form-label">Açıklama</Label>
          <div className="col-sm-9">
            <Input id="description" className="form-control" type="textarea" value={useActiveViewItem["description"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="dateTime" className="col-sm-3 col-form-label">Tarih ve Zaman</Label>
          <div className="col-sm-9">
            <Input id="dateTime" className="form-control" type="text" value={moment(useActiveViewItem["dateTime"]).format("DD/MM/YYYY HH:mm:ss")} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="recurrence" className="col-sm-3 col-form-label">Tekrar ediyor mu?</Label>
          <div className="col-sm-9">
            <Input id="recurrence" className="form-control" type="checkbox" checked={useActiveViewItem['recurrence']} readOnly style={{height:"1.5rem", width:"1.5rem"}} />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="recurrenceEndDate" className="col-sm-3 col-form-label">Tekrarın sona erdiği tarih</Label>
          <div className="col-sm-9">
            <Input id="recurrenceEndDate" className="form-control" type="text" value={moment(useActiveViewItem["recurrenceEndDate"]).format("DD/MM/YYYY HH:mm:ss")} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="selfAudio" className="col-sm-3 col-form-label">Ses kaydını kendim yapacağım</Label>
          <div className="col-sm-9">
            <Input id="selfAudio" className="form-control" type="checkbox" checked={useActiveViewItem['customVoiceFile'] ? true : false} readOnly style={{height:"1.5rem", width:"1.5rem"}} />
          </div>
        </div>
        <X xIf={!useActiveViewItem['customVoiceFile']}>
        <div className="form-group row mb-2">
          <Label for="voiceFile" className="col-sm-3 col-form-label">Ses Dosyası</Label>
          <div className="col-sm-9">
            {useActiveViewItem?.voiceFile?._id ? <audio controls><source src={audioURL(useActiveViewItem?.voiceFile?._id)} type="audio/mpeg" /></audio> : ""}
          </div>
        </div>
        </X>
        <X xIf={useActiveViewItem['customVoiceFile']}>
        <div className="form-group row mb-2">
          <Label for="customVoiceFile" className="col-sm-3 col-form-label">Özel Ses Dosyası</Label>
          <div className="col-sm-9">
            {useActiveViewItem?.customVoiceFile?._id ? <audio controls><source src={audioURL(useActiveViewItem?.customVoiceFile?._id)} type="audio/mpeg" /></audio> : ""}
          </div>
        </div>
        </X>
        
      </XModal>

      <X xIf={false}>
      <XModal 
        title="Düzenle" 
        itemTitleValue={useActiveEditItemName} 
        showModal={useEditModal} 
        toggle={toggleEditModal}
        onSubmit={()=>{handleEdit(useActiveEditItem); }}
        size="xl"
      >
        <form>
          
          <div className="form-group row mb-2">
            <Label for="title" className="col-sm-3 col-form-label">Başlık</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="name"
                id="name"
                className={`form-control ${useErrorFields.includes('name') ? 'is-invalid' : ''}`}
                type="text"
                required={true}
                value={useActiveEditItem["name"] ? useActiveEditItem["name"] : ""}
                onChange={handleEditItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="content" className="col-sm-3 col-form-label">Açıklama</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="content"
                id="content"
                className="form-control"
                type="textarea"
                style={{height: "100px"}}
                target={useActiveEditItem["content"] ? useActiveEditItem["content"] : ""}
                value={useActiveEditItem["content"]}
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="dueDate" className="col-sm-3 col-form-label">Son Tarih</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="dueDate"
                id="dueDate"
                className={`form-control ${useErrorFields.includes('dueDate') ? 'is-invalid' : ''}`}
                type="date"
                target={useActiveEditItem["dueDate"] ? useActiveEditItem["dueDate"] : ""}
                value={useActiveEditItem["dueDate"]}
                onChange={handleEditItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="imageFile" className="col-sm-3 col-form-label">Resim Dosyası</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="imageFile"
                id="imageFile"
                className="form-control"
                type="file"
                target={useActiveEditItem["imageFile"] ? useActiveEditItem["imageFile"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
            <div className="col-sm-2">
              {imageFromURL(useActiveEditItem["imageFile"]?._id, {width: 50, height: 50}, {height:"50px", width:"auto"}, useActiveEditItem["title"]) || ""}
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="voiceFile" className="col-sm-3 col-form-label">Ses</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="voiceFile"
                id="voiceFile"
                className="form-control"
                type="text"
                target={useActiveEditItem["voiceFile"] ? useActiveEditItem["voiceFile"] : ""}
                value={useVoiceTextEdit}
                onChange={e => setVoiceTextEdit(e.target.value)}
              />
            </div>
            <div className="col-sm-1">
              <Button color="primary" onClick={handleEditVoiceText}>Seslendir</Button>
            </div>
            {(useVoiceURLEdit && useVoiceURLEdit != "") ? (
              <div className="col-sm-2">
                <audio
                  controls
                  src={useVoiceURLEdit}
                  style={{height: "40px"}}
                />
              </div>
            ) : useActiveEditItem?.voiceFile && (
              <div className="col-sm-2">
                <audio
                  controls
                  src={audioURL(useActiveEditItem?.voiceFile?._id)}
                  style={{height: "40px"}}
                />
              </div>
            )}
          </div>
          

          
        </form>
      </XModal>
      </X>
      <XModal 
        title="Sil" 
        itemTitleValue={useActiveDeleteItem?.name} 
        showModal={useDeleteModal} 
        toggle={toggleDeleteModal} 
        saveButton="Evet"
        onSubmit={()=>{handleDelete(useActiveDeleteItem);}}
      >
        {`${useActiveDeleteItem?.name}`}'i silmek istediğinize emin misiniz?
      </XModal>
      <XModal
        title="Toplu Ekle"
        showModal={useMultiModal}
        toggle={toggleMultiModal}
        saveButton="Gönder"
        onSubmit={()=>{}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="multiFile" className="col-sm-3 col-form-label">Dosya</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="multiFile"
                className="form-control"
                type="file"
                multiple={true}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
        </form>
      </XModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    title: state.Layout.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBreadCrumbs: (title, breadcrumb) => dispatch(setBreadcrumbItems(title, breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reminders);
