import React, { useEffect, useState} from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { uploadFile, Request, } from "helpers/api_helper";
import { Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Progress, Row, Spinner, Tooltip } from "reactstrap";
import XModal from "components/Custom/XModal";
import { slugify } from "helpers/helpers";
import ActionsCol from "components/Custom/ActionsCol";
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { get } from "helpers/api_helper";
import { useAlert } from "providers/alertProvider";

const accessActionRoles = ['admin', 'editor'];

const dataCols = [
  {
    dataField: "name",
    text: "İsim",
    type: "text",
    required: true,
    filter: textFilter(),
  },
  
];

const cols = [
  {
    dataField: "num",
    text: "#",
    type: false
  },
  ...dataCols,
  {
    dataField: "actions",
    text: "İşlemler",
    type: false,
  }
];

const formInput = {
  marginTop: "0.5rem",
};

const url = "school";
const mySchoolUrl = "mySchool";
const pageTitle = "Okullar";
const modelSingularName = "Okul";
const modelPluralName = "Okullar";
const breadcrumbItems = [
  { title: "Anasayfa", url: "/" },
  { title: "Okullar", url: "/okullar" },
];

const Schools = props => {
  document.title = props.title || "Okullar";
  useEffect(() => {
    props.onSetBreadCrumbs(modelPluralName, breadcrumbItems);
  }, []);

  //! Core Codes
  const [useDataTableData, setDataTableData] = useState([]);
  const [useTableData, setTableData] = useState([]);
  const [useViewModal, setViewModal] = useState(false);
  const [useEditModal, setEditModal] = useState(false);
  const [useDeleteModal, setDeleteModal] = useState(false);
  const [useNewModal, setNewModal] = useState(false);
  const [useMultiModal, setMultiModal] = useState(false);
  const [useActiveViewItem, setActiveViewItem] = useState({});
  const [useActiveEditItem, setActiveEditItem] = useState({});
  const [useActiveDeleteItem, setActiveDeleteItem] = useState({});
  const [useActiveEditItemName, setActiveEditItemName] = useState('');
  const [useActiveNewItem, setActiveNewItem] = useState({});
  const [useCols, setCols] = useState(cols);
  const [useAllResponse, setAllResponse] = useState({});
  const [useUserRole, setUserRole] = useState(null);
  const [openAlert] = useAlert();
  const [useErrorFields, setErrorFields] = useState([]);
  
  const hasRoleAccess = (role) => accessActionRoles.includes(role);

  const toggleViewModal = (item) => {
    setViewModal(!useViewModal);
    item ? setActiveViewItem(item) : setActiveViewItem({});
  };
  const toggleEditModal = (item) => {
    if(item) {
      setErrorFields([]);
      setActiveEditItem(item);
      setActiveEditItemName(item.title);
      setEditModal(true);
    } else {
      setEditModal(false);
    }
  }
  const toggleDeleteModal = (item) => {
    if(item) {
      setActiveDeleteItem(item);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
    }
  }
  const toggleNewModal = () => {
    setErrorFields([]);
    setNewModal(!useNewModal);
  }
  const toggleMultiModal = () => {
    setErrorFields([]);
    setMultiModal(!useMultiModal);
  }
  
  const handleEditItemChange = (e) => {
    if(e.target.type === "file") {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target,
      });
    } else {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleNewItemChange = (e) => {
    let value = e.target;
    if(e.target.type !== "file") {
      value = value.value
    } 

    setActiveNewItem({
      ...useActiveNewItem,
      [e.target.name]: value,
    });
  };
  
  const validateFields = (activeItem) => {
    const errors = []; 
    for (let dataCol of dataCols) {
        if (dataCol.required && !activeItem[dataCol.dataField]) {
          errors.push(dataCol.dataField);
        }
    }
    setErrorFields(errors); 
    return errors.length === 0;  
  };

  const handleEdit = (activeItem) => {
    if (!validateFields(activeItem)) {
      return; 
    }
    // Check if the image typed fields in the form is set
    const deletedFields = [];
    const fileUploadPromises = [];
    for (let dataCol of dataCols.filter((dataCol) => dataCol.isForeign !== true)) {
      if (dataCol.type === "image" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "image" && activeItem[dataCol.dataField]) {
        if(activeItem[dataCol.dataField]?.files?.length > 0) {
          const file = activeItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            activeItem[dataCol.dataField] = fileId;
          }));
        }
      }
      if (dataCol.type === "file" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "file" && activeItem[dataCol.dataField]) {
        if(activeItem[dataCol.dataField]?.files?.length > 0) {
          const file = activeItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            activeItem[dataCol.dataField] = fileId;
          }));
        }
      }
      if (dataCol.readonly && dataCol.dataField === "slug") {
        activeItem.slug = slugify(activeItem.name);
        delete activeItem.slug;
      }
    }
    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveEditItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveEditItem[key];
        }
      });
      Request.put(`/${url}/${activeItem?._id}`, activeItem)
      .then(response => {
        if (response) {
          openAlert(`${modelSingularName} bilgileri güncellendi`);
        } else {
          openAlert("İşlem başarısız, lütfen tekrar deneyin.", "danger");
        }
        return Request.get(`/${url}/${mySchoolUrl}`);
      })
      .then(response => response.data)
      .then(data => {
        fetchEntire();
      })
      .then(() => {
        toggleEditModal();
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });  
    });
  };

  useEffect(() => {
    if (!useEditModal) {
      setActiveEditItem({});
    }
  }, [useEditModal]);

  const handleNew = () => {
    if (!validateFields(useActiveNewItem)) {
      return; 
    }
    const fileUploadPromises = [];
    for (let dataCol of dataCols) {
      if (dataCol.type === "image" && useActiveNewItem[dataCol.dataField]) {
        const file = useActiveNewItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(file).then((response) => {
          const fileId = response._id;
          useActiveNewItem[dataCol.dataField] = fileId;
        }));
      } else if(dataCol.type === "file" && useActiveNewItem[dataCol.dataField]) {
        const file = useActiveNewItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(file).then((response) => {
          const fileId = response._id;
          useActiveNewItem[dataCol.dataField] = fileId;
        }));
      }
    }
    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveNewItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveNewItem[key];
        }
      });
      Request.post(`/${url}`, useActiveNewItem)
      .then(response => {
        if (response) {
          openAlert(`Yeni ${modelSingularName} oluşturuldu`);
        } else {
          openAlert("İşlem başarısız, lütfen tekrar deneyin.", "danger");
        }

        return Request.get(`/${url}/${mySchoolUrl}`);
      })
      .then(response => response.data)
      .then(data => {
        fetchEntire();
      })
      .then(() => {
        toggleNewModal();
        setActiveNewItem({});
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
    });
  };

  const handleDelete = (activeItem) => {
    Request.del(`/${url}/${activeItem._id}`)
    .then(response => {
      if (response) {
        openAlert(`${modelSingularName} silindi`);
      } else {
        openAlert("İşlem başarısız, lütfen tekrar deneyin.", "danger");
      }

      return Request.get(`/${url}/${mySchoolUrl}`);
    })
    .then(response => response.data)
    .then(data => {
      fetchEntire();
    })
    .then(() => {
      toggleDeleteModal();
    })
    .catch(() => {
      openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
    });
  };

  useEffect(() => {
    if (!useDeleteModal) {
      setActiveDeleteItem({});
    }
  }, [useDeleteModal]);

  const fetchTableData = (data, role) => {
    if(data &&  data.length > 0 && Array.isArray(data)) {
      const mapped = data.map((item, index) => {
        return {
          num: index + 1,
          name: item.name,
          actions: 
            <ActionsCol 
              id={item._id} 
              onView={() => { toggleViewModal(item) }}
              onEdit={hasRoleAccess(role)
                        ? () => { toggleEditModal(item) } 
                        : null} 
              onDelete={hasRoleAccess(role) 
                        ? () => { toggleDeleteModal(item) } 
                        : null} 
            />,
        }
      });
      return mapped;
    }
    return [];
  };
  
  const getUserRole = async () => {
    const userResponse = await get("/user/me");
    const role = userResponse.role;
    setUserRole(role);
    return role;
  };

  const fetchEntire = async () => {
    const role = await getUserRole();
    
    return Request.get(`/${url}/${mySchoolUrl}`)
      .then(response => {
        setDataTableData(response.data);
        setAllResponse(response.data);
        return response.data;
      })
      .then(data => {
        const fetchedTableData = fetchTableData(data, role);
        setTableData(fetchedTableData);
        return fetchedTableData;
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
  };  

  useEffect(() => {
    fetchEntire();
  }, []);
  //! End Core Codes
  

  return (
    // <DataTable dataCols={dataCols} cols={cols} url={url} modelPluralName={modelPluralName} pageTitle={pageTitle} />
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {pageTitle}
                    {
                      (hasRoleAccess(useUserRole)) && (
                        <Button color="success" style={{ marginLeft: "1rem" }} onClick={toggleNewModal}>
                          Yeni Ekle
                        </Button>
                      )
                    }
                  </CardTitle>
                  Toplam {useAllResponse?.length} {modelSingularName} bulunmaktadır.
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="num"
                      data={useTableData}
                      columns={useCols}
                      filter={filterFactory()}
                      filterPosition="inline"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      

      <XModal 
        title="Yeni Ekle" 
        showModal={useNewModal}
        toggle={toggleNewModal}
        onSubmit={()=>{handleNew();}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="name" className="col-sm-3 col-form-label">İsim</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="name"
                id="name"
                className={`form-control ${useErrorFields.includes('name') ? 'is-invalid' : ''}`}
                type="text"
                required={true}
                value={useActiveNewItem["name"] ? useActiveNewItem["name"] : ""}
                onChange={handleNewItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
        </form>
      </XModal>
      <XModal title="Görüntüle" itemTitleValue={useActiveViewItem?.name} showModal={useViewModal} toggle={toggleViewModal} saveButton={false}>
    
        <div className="form-group row mb-2">
          <Label for="name" className="col-sm-3 col-form-label">İsim</Label>
          <div className="col-sm-9">
            <Input id="name" className="form-control" type="text" value={useActiveViewItem["name"]} readOnly />
          </div>
        </div>
        
      </XModal>
      <XModal 
        title="Düzenle" 
        itemTitleValue={useActiveEditItemName} 
        showModal={useEditModal} 
        toggle={toggleEditModal}
        onSubmit={()=>{handleEdit(useActiveEditItem); }}
      >
        <form>
          
          <div className="form-group row mb-2">
            <Label for="title" className="col-sm-3 col-form-label">Başlık</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="name"
                id="name"
                className={`form-control ${useErrorFields.includes('name') ? 'is-invalid' : ''}`}
                type="text"
                required={true}
                value={useActiveEditItem["name"] ? useActiveEditItem["name"] : ""}
                onChange={handleEditItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          

          
        </form>
      </XModal>
      <XModal 
        title="Sil" 
        itemTitleValue={useActiveDeleteItem?.title} 
        showModal={useDeleteModal} 
        toggle={toggleDeleteModal} 
        saveButton="Evet"
        onSubmit={()=>{handleDelete(useActiveDeleteItem);}}
      >
        {`${useActiveDeleteItem?.title}`}'i silmek istediğinize emin misiniz?
      </XModal>
      <XModal
        title="Toplu Ekle"
        showModal={useMultiModal}
        toggle={toggleMultiModal}
        saveButton="Gönder"
        onSubmit={()=>{}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="multiFile" className="col-sm-3 col-form-label">Dosya</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="multiFile"
                className="form-control"
                type="file"
                multiple={true}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
        </form>
      </XModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    title: state.Layout.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBreadCrumbs: (title, breadcrumb) => dispatch(setBreadcrumbItems(title, breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Schools);
