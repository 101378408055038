import React, { useEffect, useState} from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { imageFromURL, uploadFile, Request, text2Speech, audioURL } from "helpers/api_helper";
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Row, } from "reactstrap";
import XModal from "components/Custom/XModal";
import ActionsCol from "components/Custom/ActionsCol";
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import X from "components/Custom/X";
import { token } from "helpers/helpers";
import { useAlert } from "providers/alertProvider";
import { set } from "lodash";

const dataCols = [
  {
    dataField: "lesson",
    text: "Ders",
    type: "select",
  },
  {
    dataField: "topic",
    text: "Konu",
    type: "select",
  },
  {
    dataField: "chapter",
    text: "Bölüm",
    type: "select",
  },
  {
    dataField: "order",
    text: "Sıra",
    type: "number",
  },
  {
    dataField: "questionType",
    text: "Alıştırma Tipi",
    type: "select",
  },
  {
    dataField: "question",
    text: "Alıştırma",
    type: "text",
  },
  {
    dataField: "options",
    text: "Cevaplar",
    type: "text",
  },
  {
    dataField: "correctAnswer",
    text: "Doğru Cevap",
    type: "text",
  },
  {
    dataField: "voiceFile",
    text: "Ses Dosyası",
    type: "file",
  },
];

const cols = [
  {
    dataField: "num",
    text: "#",
    type: false
  },
  {
    dataField: "lesson",
    text: "Ders",
    type: "text",
    editable: false,
    filter: textFilter(),
  },
  {
    dataField: "topic",
    text: "Konu",
    type: "text",
    editable: false,
    filter: textFilter(),
  },
  {
    dataField: "chapter",
    text: "Bölüm",
    type: "text",
    editable: false,
    filter: textFilter(),
  },
  {
    dataField: "order",
    text: "Sıra",
    type: "number",
    editor: {
      type: Type.TEXT,
    },
  },
  {
    dataField: "questionType",
    text: "Alıştırma Tipi",
    type: "text",
    filter: textFilter(),
  },
  {
    dataField: "question",
    text: "Alıştırma",
    type: "text",
    editable: false,
  },
  {
    dataField: "actions",
    text: "İşlemler",
    type: false,
    editable: false,
  }
];

const formInput = {
  marginTop: "0.5rem",
};

const url = "question/exercise";
const pageTitle = "Alıştırmalar";
const modelPluralName = "Alıştırmalar";
const breadcrumbItems = [
  { title: "Anasayfa", url: "/" },
  { title: "Alıştırmalar", url: "/alistimalar" },
];

const questionTypes = {
  multipleChoicesString: "Çoktan Seçmeli (Metin)",
  multipleChoicesFile: "Çoktan Seçmeli (Dosya)",
  multipleChoicesStringWithFile: "Çoktan Seçmeli (Metin ve Dosya)",
  voiceoverWriting: "Sesli Mesajı Anlama ve Yazma",
  userVoiceover: "Kullanıcının Seslendirmesi",
  formingSentences: "Cümle Kurma",
};

const Exercises = props => {
  document.title = props.title || "Alıştırmalar";
  useEffect(() => {
    props.onSetBreadCrumbs(modelPluralName, breadcrumbItems);
  }, []);

  //! Core Codes
  const [useDataTableData, setDataTableData] = useState([]);
  const [useTableData, setTableData] = useState([]);
  const [useViewModal, setViewModal] = useState(false);
  const [useDeleteModal, setDeleteModal] = useState(false);
  const [useNewModal, setNewModal] = useState(false);
  const [useMultiModal, setMultiModal] = useState(false);
  const [useActiveViewItem, setActiveViewItem] = useState({});
  const [useActiveDeleteItem, setActiveDeleteItem] = useState({});
  const [useActiveNewItem, setActiveNewItem] = useState({});
  const [useCols, setCols] = useState(cols);
  const [useChapters, setChapters] = useState([]);
  const [useAllResponse, setAllResponse] = useState({});
  const [useLessons, setLessons] = useState([]);
  const [useTopicsNew, setTopicsNew] = useState([]);
  const [useChaptersNew, setChaptersNew] = useState([]);
  const [useSelectedLessonNew, setSelectedLessonNew] = useState("");
  const [useSelectedTopicNew, setSelectedTopicNew] = useState("");
  const [useSelectedChapterNew, setSelectedChapterNew] = useState("");
  const [useSelectedQuestionTypeNew, setSelectedQuestionTypeNew] = useState("multipleChoicesString");
  const [useHasPictureNew, setHasPictureNew] = useState(false);
  const [usePictureNew, setPictureNew] = useState("");
  const [usePictureNewFileId, setPictureNewFileId] = useState("");
  const [useHasVoiceNew, setHasVoiceNew] = useState(false);
  const [useVoiceTextNew, setVoiceTextNew] = useState("");
  const [useVoiceNew, setVoiceNew] = useState("");
  const [useVoiceURL, setVoiceURL] = useState("");
  const [useVoiceId, setVoiceId] = useState("");
  const [useHasTextNew, setHasTextNew] = useState(false);
  const [useTextNew, setTextNew] = useState("");
  const [useOptions, setOptions] = useState([]);
  const [useOptionsOptions, setOptionsOptions] = useState([]);
  const [useOptionsVoiceTexts, setOptionsVoiceTexts] = useState([]);
  const [useOptionsVoiceURLs, setOptionsVoiceURLs] = useState([]);
  const [useOptionsVoiceIds, setOptionsVoiceIds] = useState([]);
  const [useOptionsTexts, setOptionsTexts] = useState([]);
  const [useOptionsPictures, setOptionsPictures] = useState([]);
  const [useOptionsPictureIds, setOptionsPictureIds] = useState([]); 
  const [useQuestionPoint, setQuestionPoint] = useState(0);
  const [useCorrectAnswerHasIndex, setCorrectAnswerHasIndex] = useState(true);
  const [useCorrectAnswerHasText, setCorrectAnswerHasText] = useState(false);
  const [useCorrectAnswerHasFile, setCorrectAnswerHasFile] = useState(false);
  const [useCorrectAnswerIndex, setCorrectAnswerIndex] = useState(0);
  const [useCorrectAnswerText, setCorrectAnswerText] = useState("");
  const [useCorrectAnswerTexts, setCorrectAnswerTexts] = useState(['']);
  const [useCorrectAnswerFile, setCorrectAnswerFile] = useState("");
  const [useCorrectAnswerFileId, setCorrectAnswerFileId] = useState("");
  const [useIsFormingSentencesQuestionNew, setIsFormingSentencesQuestionNew] = useState(false);
  const [useIsFormingSentencesQuestionNewCorrectAnswers, setIsFormingSentencesQuestionNewCorrectAnswers] = useState([]);
  const [useCorrectAnswerHasVoiceNew, setCorrectAnswerHasVoiceNew] = useState(false);
  const [useCorrectAnswerVoiceTextNew, setCorrectAnswerVoiceTextNew] = useState("");
  const [useCorrectAnswerVoiceURLNew, setCorrectAnswerVoiceURLNew] = useState("");
  const [useCorrectAnswerVoiceIdNew, setCorrectAnswerVoiceIdNew] = useState("");
  const {openAlert} = useAlert();

  const toggleViewModal = (item) => {
    setViewModal(!useViewModal);
    if(item) {
      setActiveViewItem(item);
    }  else {
      setActiveViewItem({});
    }
  };
  const toggleDeleteModal = (item) => {
    if(item) {
      setActiveDeleteItem(item);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
    }
  }
  const toggleNewModal = () => {
    setNewModal(!useNewModal);
  }
  const toggleMultiModal = () => {
    setMultiModal(!useMultiModal);
  }

  const onMoveUp = (item) => {
    if(item.order > 1) {
      Request.post(`/${url}/move/${item._id}/${item.order - 1}`, {})
      .then(response => {
        if (!response) {
          throw new Error();
        } 
        fetchEntire();
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
    }
  };

  const onMoveDown = (item) => {
    Request.post(`/${url}/move/${item._id}/${item.order + 1}`, {})
    .then(response => {
      fetchEntire();
      openAlert("Öğe başarıyla aşağı taşındı.");
    })
    .catch(() => {
      openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
    });
  };

  const handleNewItemChange = (e) => {
    let value = e.target;
    if(e.target.type !== "file") {
      value = value.value
    } 

    setActiveNewItem({
      ...useActiveNewItem,
      [e.target.name]: value,
    });
  };

  const handleNew = () => {
    const question = {};
    if(useHasPictureNew) {
      question.imageFiles = [usePictureNewFileId];
    }
    if(useHasVoiceNew) {
      question.voiceFiles = [useVoiceId];
    }
    if(useHasTextNew) {
      question.texts = [useTextNew];
    }

    const options = [];
    for(let i = 0; i < useOptions.length; i++) {
      const option = {};
      if(useOptionsOptions[i]?.hasPicture) {
        option.valueImageFile = useOptionsPictureIds[i];
      }
      if(useOptionsOptions[i]?.hasVoice) {
        option.valueVoiceFile = useOptionsVoiceIds[i];
      }
      if(useOptionsOptions[i]?.hasText) {
        option.value = useOptionsTexts[i];
      }
      options.push(option);
    }

    const correctAnswer = {};
    if(useCorrectAnswerHasIndex) {
      correctAnswer.index = parseInt(useCorrectAnswerIndex);
    }
    if(useCorrectAnswerHasText) {
      // correctAnswer.text = useCorrectAnswerText;
      const texts = useCorrectAnswerTexts.filter(text => text !== "");
      correctAnswer.texts = texts;
    }
    if(useCorrectAnswerHasFile) {
      correctAnswer.file = useCorrectAnswerFileId;
    }
    if(useCorrectAnswerHasVoiceNew) {
      correctAnswer.voiceFile = useCorrectAnswerVoiceIdNew;
    }

    const data = {
      lesson: useSelectedLessonNew,
      topics: useSelectedTopicNew,
      chapter: useSelectedChapterNew,
      point: useQuestionPoint,
      questionType: useSelectedQuestionTypeNew,
      question,
      options,
      correctAnswer,
    };

    if(useIsFormingSentencesQuestionNew) {
      data.correctAnswer = {
        customValue: {
          answer: useIsFormingSentencesQuestionNewCorrectAnswers,
        },
      };
      if(!data.question.texts && !data.question.voiceFiles && !data.question.imageFiles) {
        data.question.texts = [""];
      }
    }

    (async () => {
      try {
        const response = await Request.post(`/${url}`, data);
        if (!response) {
          throw new Error();
        }
        toggleNewModal();
        await fetchEntire();
        resetAll();
      } catch (error) {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      }
    })();
    
  };

  const resetAll = async () => {
    setActiveNewItem({});
    setSelectedLessonNew("");
    setSelectedTopicNew("");
    setSelectedChapterNew("");
    setSelectedQuestionTypeNew("multipleChoicesString");
    setHasPictureNew(false);
    setPictureNew("");
    setPictureNewFileId("");
    setHasVoiceNew(false);
    setVoiceId("");
    setVoiceURL("");
    setVoiceTextNew("");
    setHasTextNew(false);
    setTextNew("");
    setOptions([{}]);
    setOptionsOptions([
      {hasPicture: false, hasVoice: false, hasText: false}, 
      {hasPicture: false, hasVoice: false, hasText: false},
    ]);
    setOptionsVoiceTexts([]);
    setOptionsVoiceURLs([]);
    setOptionsVoiceIds([]);
    setOptionsTexts([]);
    setOptionsPictures([]);
    setOptionsPictureIds([]);
    setQuestionPoint(0);
    setCorrectAnswerHasIndex(true);
    setCorrectAnswerHasText(false);
    setCorrectAnswerHasFile(false);
    setCorrectAnswerIndex(0);
    setCorrectAnswerText("");
    setCorrectAnswerTexts(['']);
    setCorrectAnswerFile("");
    setCorrectAnswerFileId("");
    setIsFormingSentencesQuestionNew(false);
    setIsFormingSentencesQuestionNewCorrectAnswers([]);
    setCorrectAnswerHasVoiceNew(false);
    setCorrectAnswerVoiceTextNew("");
    setCorrectAnswerVoiceURLNew("");
    setCorrectAnswerVoiceIdNew("");
    await fetchEntire();
  };

  const handleDelete = (activeItem) => {
    Request.del(`/${url}/${activeItem._id}`)
    .then(response => {
      return Request.get(`/${url}`);
    })
    .then(response => {
      if (!response) {
        throw new Error();
      }
      setDataTableData(response.data);
      fetchEntire();
      toggleDeleteModal();
    })
    .catch(() => {
      openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
    });
  };

  useEffect(() => {
    if (!useDeleteModal) {
      setActiveDeleteItem({});
    }
  }, [useDeleteModal]);

  const fetchTableData = (data) => {
    data = data;
    if(data &&  data.length > 0 && Array.isArray(data)) {
      const mapped = data.map((item, index) => {
        let res ={
          num: index + 1,
          lesson: item.lesson?.name,
          topic: item.topics?.name,
          chapter: item.chapter?.name,
          order: item.order,
          questionType: item.questionType,
          point: item.point,
          _id: item._id,
          
          actions: 
            <ActionsCol 
              id={item._id} 
              onView={()=>{toggleViewModal(item); }}
              onDelete={()=>{toggleDeleteModal(item)}}
              onMoveUp={()=>{onMoveUp(item)}}
              onMoveDown={()=>{onMoveDown(item)}}
            />,
        };
        if(item?.question?.imageFiles && item.question?.imageFiles.length > 0) {
          res.question = imageFromURL(item.question?.imageFiles[0], {width: 50, height: 50}, {height:"50px", width:"auto"} );
        } else if (item?.question?.texts && item.question?.texts.length > 0) {
          res.question = item.question?.texts[0].substring(0, 100);
        } else if (item?.question?.voiceFiles && item.question?.voiceFiles.length > 0) {
          res.question = <audio controls src={audioURL(item.question?.voiceFiles[0])} style={{height: "40px"}} />;
        }
        return res;
      });
      return mapped;
    }
    return [];
  };
  const fetchChapters = () => {
    try {
      const response = Request.get("/chapter");
      setChapters(response.data);
    } catch {
      openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
    }
    
  };

  const sortData = data => {
    data.sort((a, b) => {
      return a.order - b.order;
    });
    return data;
  };

  const fetchEntire = async () => {
  try {
    let response = await Request.get(`/${url}`);
    let data = response.data;
    data = sortData(data);

    setDataTableData(data);
    setAllResponse(data);

    const fetchedTableData = fetchTableData(data);
    setTableData(fetchedTableData);

    await fetchChapters(); 

    const lessonResponse = await Request.get(`/lesson`);
    const lessonData = lessonResponse.data;
    setLessons(lessonData);

    const activeLesson = lessonData[0]?._id;
    setActiveNewItem({...useActiveNewItem, lesson: activeLesson});
    setSelectedLessonNew(activeLesson);

    const topicResponse = await Request.get(`/topic/byLesson/${activeLesson}`);
    const topicData = topicResponse.data;
    setTopicsNew(topicData);

    const activeTopic = topicData[0]?._id;
    setActiveNewItem({...useActiveNewItem, topic: activeTopic});
    setSelectedTopicNew(activeTopic);

    const chapterResponse = await Request.get(`/chapter/byTopic/${activeTopic}`);
    const chapterData = chapterResponse.data;
    setChaptersNew(chapterData);
    const activeChapter = chapterData[0]?._id;
    setSelectedChapterNew(activeChapter);
    
  } catch (error) {
    openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
  }
};

  useEffect(() => {
    fetchEntire();
    setOptions([{}]);
    setOptionsOptions([
      {hasPicture: false, hasVoice: false, hasText: false}, 
    ]);
  }, []);

  useEffect(() => {
    if(useSelectedLessonNew && useSelectedLessonNew != '') {
      Request.get(`/topic/byLesson/${useSelectedLessonNew}`)
      .then(response => {
        setTopicsNew(response.data);
        setSelectedTopicNew(response.data[0]?._id);
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
    }
  }, [useSelectedLessonNew]);

  useEffect(() => {
    if(usePictureNew.type) {
      const file = usePictureNew.files[0];
      if(file && file != "") {
        uploadFile(file).then(response => {
          setPictureNewFileId(response._id);
        });
      }
    } else {
      setPictureNewFileId("");
    }
  }, [usePictureNew]);

  useEffect(() => {
    if(useCorrectAnswerFile.type) {
      const file = useCorrectAnswerFile.files[0];
      if(file && file != "") {
        uploadFile(file).then(response => {
          setCorrectAnswerFileId(response._id);
        });
      }
    }
  }, [useCorrectAnswerFile]);

  useEffect(() => {
    if(useSelectedQuestionTypeNew == "formingSentences") {
      setIsFormingSentencesQuestionNew(true);
      const optionsCount = useOptions.length;
      const correctAnswers = [];
      for(let i = 0; i < optionsCount; i++) {
        correctAnswers.push(i);
      }
      setIsFormingSentencesQuestionNewCorrectAnswers(correctAnswers);
    } else {
      setIsFormingSentencesQuestionNew(false);
    }
  }, [useSelectedQuestionTypeNew, useOptions])

  useEffect(() => {
    if(useSelectedTopicNew) {
      const activeTopic = useSelectedTopicNew;
      Request.get(`/chapter/byTopic/${activeTopic}`)
      .then(chapterResponse => {
        const chapterData = chapterResponse.data;
        setChaptersNew(chapterData);
        const activeChapter = chapterData[0]?._id;
        setSelectedChapterNew(activeChapter);
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
    }
  }, [useSelectedTopicNew])
  //! End Core Codes

  const handleIsFormingSentencesQuestionNewCorrectAnswers = (index, value) => {
    let correctAnswers = useIsFormingSentencesQuestionNewCorrectAnswers;
    correctAnswers[index] = parseInt(value);
    setIsFormingSentencesQuestionNewCorrectAnswers([...correctAnswers]);
  }

  const handleNewVoiceText = () => {
    if(useVoiceTextNew && useVoiceTextNew != "") {
      text2Speech(useVoiceTextNew).then(response => {
        setVoiceURL(response.url);
        setVoiceId(response?.id);
      });
    }
  };

  const handleCorrectAnswerVoiceTextNew = () => {
    if(useCorrectAnswerVoiceTextNew && useCorrectAnswerVoiceTextNew != "") {
      text2Speech(useCorrectAnswerVoiceTextNew).then(response => {
        setCorrectAnswerVoiceURLNew(response.url);
        setCorrectAnswerVoiceIdNew(response.id);
      });
    }
  };

  const increaseOptionCount = () => {
    const options = useOptions;
    options.push({
      hasPicture: false,
      hasVoice: false,
      hasText: false,
    });
    setOptions([...options]);

    const optionsOptions = useOptionsOptions;
    optionsOptions.push({
      hasPicture: false,
      hasVoice: false,
      hasText: false,
    });
    setOptionsOptions([...optionsOptions]);
  }
  const removeFromOptions = (index) => {
    if(useOptions.length > 0) {
      useOptions.splice(index, 1);
      setOptions([...useOptions]);
    }
  }

  const handleOptionsOptions = (index, option, value) => {
    const optionsOptions = useOptionsOptions;
    optionsOptions[index][option] = value;
    setOptionsOptions([...optionsOptions]);
  }

  const sendOptionsVoiceText = (index) => {
    if(useOptionsVoiceTexts[index] && useOptionsVoiceTexts[index] != "") {
      text2Speech(useOptionsVoiceTexts[index]).then(response => {
        useOptionsVoiceURLs[index] = response.url;
        useOptionsVoiceIds[index] = response.id;
        setOptionsVoiceURLs([...useOptionsVoiceURLs]);
        setOptionsVoiceIds([...useOptionsVoiceIds]);
      });
    }
  }

  const handleOptionsVoiceText = (index, value) => {
    const optionsVoiceTexts = useOptionsVoiceTexts;
    optionsVoiceTexts[index] = value;
    setOptionsVoiceTexts([...optionsVoiceTexts]);
  }

  const handleOptionsPicture = (index, value) => {
    const optionsPictures = useOptionsPictures;
    optionsPictures[index] = value;
    setOptionsPictures([...optionsPictures]);
    if(value.type) {
      const file = value.files[0];
      if(file && file != "") {
        uploadFile(file).then(response => {
          useOptionsPictureIds[index] = response._id;
          setOptionsPictureIds([...useOptionsPictureIds]);
        });
      }
    }
  }

  const handleOptionsText = (index, value) => {
    const optionsTexts = useOptionsTexts;
    optionsTexts[index] = value;
    setOptionsTexts([...optionsTexts]);
  }

  const cellEdit = cellEditFactory({
    mode: 'dbclick',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, row, column) => {
      const objectId = row?._id;
      if(objectId) {
        const newOrder = newValue;
        Request.post(`/${url}/move/${objectId}/${newOrder}`, {})
        .then(response => {
          fetchEntire();
        })
        .catch(() => {
          openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
        });
      }
    },
  });

  const addCorrectAnswerText = () => {
    setCorrectAnswerTexts([...useCorrectAnswerTexts, '']);
  };
  
  const removeCorrectAnswerText = (index) => {
    const newCorrectAnswerTexts = useCorrectAnswerTexts.filter((_, i) => i !== index);
    setCorrectAnswerTexts(newCorrectAnswerTexts);
  };
  
  const handleCorrectAnswerTextChange = (index, value) => {
    const newCorrectAnswerTexts = useCorrectAnswerTexts.map((text, i) => (i === index ? value : text));
    setCorrectAnswerTexts(newCorrectAnswerTexts);
  };

  
  return (
    // <DataTable dataCols={dataCols} cols={cols} url={url} modelPluralName={modelPluralName} pageTitle={pageTitle} />
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {pageTitle}
                    <Button color="success" style={{marginLeft: "1rem"}} onClick={toggleNewModal}>
                      Yeni Ekle
                    </Button>
                  </CardTitle>
                  Toplam {useAllResponse.objectCount} okul bulunmaktadır.
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="num"
                      data={useTableData}
                      columns={useCols}
                      filter={filterFactory()}
                      filterPosition="inline"
                      cellEdit={ cellEdit }
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      

      <XModal 
        title="Yeni Alıştırma Ekle" 
        showModal={useNewModal}
        toggle={toggleNewModal}
        onSubmit={()=>{handleNew();}}
        size="xl"
        fullScreen={true}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="lesson" className="col-sm-1 col-form-label">Ders</Label>
            <div className="col-sm-11" style={formInput}>
              <Input
                name="lesson"
                className="form-control"
                type="select"
                required={true}
                value={useActiveNewItem["lesson"] ? useActiveNewItem["lesson"] : null}
                onChange={(e) => {
                  setActiveNewItem({...useActiveNewItem, [e.target.name]: e.target.value});
                  setSelectedLessonNew(e.target.value);
                }}
              >
                {useLessons.map((lesson, index) => (
                  <option value={lesson._id}>{lesson.name}</option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="topic" className="col-sm-1 col-form-label">Konu</Label>
            <div className="col-sm-11" style={formInput}>
              <Input
                name="topic"
                className="form-control"
                type="select"
                value={useActiveNewItem["topic"] ? useActiveNewItem["topic"] : null}
                onChange={(e) => {
                  setActiveNewItem({...useActiveNewItem, [e.target.name]: e.target.value}) ;
                  setSelectedTopicNew(e.target.value);
                }}
              >
                {useTopicsNew.map((topic, index) => (
                  <option value={topic._id}>{topic.name}</option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="chapter" className="col-sm-1 col-form-label">Bölüm</Label>
            <div className="col-sm-11" style={formInput}>
              <Input
                name="chapter"
                className="form-control"
                type="select"
                value={useActiveNewItem["chapter"] ? useActiveNewItem["chapter"] : null}
                onChange={(e) => setSelectedChapterNew(e.target.value) }
              >
                {useChaptersNew.map((chapter, index) => (
                  <option value={chapter._id}>{chapter.name}</option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="questionType" className="col-sm-1 col-form-label">Alıştırma Tipi</Label>
            <div className="col-sm-11" style={formInput}>
              <Input
                name="questionType"
                className="form-control"
                type="select"
                value={useSelectedQuestionTypeNew ? useSelectedQuestionTypeNew : null}
                onChange={(e) => { setSelectedQuestionTypeNew(e.target.value); } }
              >
                {Object.entries(questionTypes).map(([key, value]) => (
                  <option value={key}>{value}</option>
                ))}
              </Input>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="question" className="col-sm-1 col-form-label">Alıştırma</Label>
            <div className="col-sm-11" style={formInput}>
              <FormGroup check inline>
                <Input
                  id="hasPictureNew"
                  name="hasPictureNew"
                  className="form-control checkbox-input"
                  type="checkbox"
                  value={useHasPictureNew}
                  onChange={(e) => setHasPictureNew(e.target.checked)}
                />
                <Label for="hasPictureNew" className="col-form-label checkbox-label">Resimli Alıştırma</Label>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  id="hasVoiceNew"
                  name="hasVoiceNew"
                  className="form-control checkbox-input"
                  type="checkbox"
                  value={useHasVoiceNew}
                  onChange={(e) => setHasVoiceNew(e.target.checked)}
                />
                <Label for="hasVoiceNew" className="col-form-label checkbox-label">Sesli Alıştırma</Label>
              </FormGroup>
              <FormGroup check inline>
                <Input
                  id="hasTextNew"
                  name="hasTextNew"
                  className="form-control checkbox-input"
                  type="checkbox"
                  value={useHasTextNew}
                  onChange={(e) => setHasTextNew(e.target.checked)}
                />
                <Label for="hasTextNew" className="col-form-label checkbox-label">Metinli Alıştırma</Label>
              </FormGroup>
              {useHasPictureNew && (
                <Row>
                  <Col>
                    <Label for="pictureNew" className="col-sm-1 col-form-label">Resim</Label>
                    <Row>
                      <div className="col-sm-9" style={formInput}>
                        <Input
                          name="pictureNew"
                          id="pictureNew"
                          className="form-control"
                          type="file"
                          target={usePictureNew ? usePictureNew : null}
                          onChange={(e) => setPictureNew(e.target)}
                        />
                      </div>
                      <div className="col-sm-2">
                        {imageFromURL(usePictureNewFileId, {width: 50, height: 50}, {height:"50px", width:"auto"}, useActiveNewItem["name"]) || ""}
                      </div>
                    </Row>
                  </Col>
                </Row>
              )}
              {useHasVoiceNew && (
                <Row>
                  <Col>
                    <Label for="voiceNew" className="col-sm-1 col-form-label">Ses</Label>
                    <div className="col-sm-11" style={formInput}>
                      <Row>
                        <div className="col-sm-5">
                          <Input
                            name="voiceTextNew"
                            id="voiceTextNew"
                            className="form-control"
                            type="text"
                            placeholder="Seslendirilecek Metin"
                            onChange={(e) => setVoiceTextNew(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-1">
                          <Button color="primary" onClick={handleNewVoiceText}>Seslendir</Button>
                        </div>
                        {useVoiceURL && useVoiceURL != "" && (
                          <div className="col-sm-2">
                            <audio
                              controls
                              src={useVoiceURL}
                              style={{height: "40px"}}
                            />
                          </div>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              )}
              {useHasTextNew && (
                <Row>
                  <Col>
                    <Label for="textNew" className="col-sm-1 col-form-label">Metin</Label>
                    <div className="col-sm-11" style={formInput}>
                      <Input
                        name="textNew"
                        id="textNew"
                        className="form-control"
                        type="textarea"
                        style={{height: "200px"}}
                        value={useTextNew ? useTextNew : ""}
                        onChange={(e) => setTextNew(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>

          <div className="form-group row mb-2">
            <Label for="question" className="col-sm-1 col-form-label">
              Seçenekler
            </Label>
            <div className="col-sm-11" style={formInput}>
              {useOptions.map((option, index) => (
                  <Row style={{marginBottom: "10px"}}>
                    <Col>
                      <Row>
                        <Col style={{fontSize:"1rem", fontWeight:"bold"}}>
                          Seçenek
                        </Col>
                      </Row>
                      <FormGroup check inline>
                        <Input
                          id={`hasPictureNew${index}`}
                          name={`hasPictureNew${index}`}
                          className="form-control checkbox-input"
                          type="checkbox"
                          value={useOptionsOptions[index]?.hasPicture}
                          onChange={(e) => handleOptionsOptions(index, "hasPicture", e.target.checked)}
                        />
                        <Label for={`hasPictureNew${index}`} className="col-form-label checkbox-label">Resimli Seçenek</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          id={`hasVoiceNew${index}`}
                          name={`hasVoiceNew${index}`}
                          className="form-control checkbox-input"
                          type="checkbox"
                          value={useOptionsOptions[index]?.hasVoice}
                          onChange={(e) => handleOptionsOptions(index, "hasVoice", e.target.checked)}
                        />
                        <Label for={`hasVoiceNew${index}`} className="col-form-label checkbox-label">Sesli Seçenek</Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Input
                          id={`hasTextNew${index}`}
                          name={`hasTextNew${index}`}
                          className="form-control checkbox-input"
                          type="checkbox"
                          value={useOptionsOptions[index]?.hasText}
                          onChange={(e) => handleOptionsOptions(index, "hasText", e.target.checked)}
                        />
                        <Label for={`hasTextNew${index}`} className="col-form-label checkbox-label">Metinli Seçenek</Label>
                      </FormGroup>
                      {useOptionsOptions[index]?.hasPicture && (
                        <Row>
                          <Col>
                            <Label for={`optionPictureNew${index}`} className="col-sm-1 col-form-label">Resim</Label>
                            <Row>
                              <div className="col-sm-9" style={formInput}>
                                <Input
                                  name={`optionPictureNew${index}`}
                                  id={`optionPictureNew${index}`}
                                  className="form-control"
                                  type="file"
                                  target={useOptionsOptions[index]?.picture ? useOptionsOptions[index]?.picture : null}
                                  onChange={(e) => handleOptionsPicture(index, e.target)}
                                />
                              </div>
                              <div className="col-sm-2">
                                {imageFromURL(useOptionsPictureIds[index], {width: 50, height: 50}, {height:"50px", width:"auto"}, useActiveNewItem["name"]) || ""}
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      )}
                      {useOptionsOptions[index]?.hasVoice && (
                        <Row>
                          <Col>
                            <Label for={`optionVoiceNew${index}`} className="col-sm-1 col-form-label">Ses</Label>
                            <div className="col-sm-11" style={formInput}>
                              <Row>
                                <div className="col-sm-5">
                                  <Input
                                    name={`optionVoiceNew${index}`}
                                    id={`optionVoiceNew${index}`}
                                    className="form-control"
                                    type="text"
                                    placeholder="Seslendirilecek Metin"
                                    onChange={(e) => handleOptionsVoiceText(index, e.target.value)}
                                  />
                                </div>
                                <div className="col-sm-1">
                                  <Button color="primary" onClick={() => sendOptionsVoiceText(index)}>Seslendir</Button>
                                </div>
                                {useOptionsVoiceURLs[index] && useOptionsVoiceURLs[index] != "" && (
                                  <div className="col-sm-2">
                                    <audio
                                      controls
                                      src={useOptionsVoiceURLs[index]}
                                      style={{height: "40px"}}
                                    />
                                  </div>
                                )}
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      )}
                      {useOptionsOptions[index]?.hasText && (
                        <Row>
                          <Col>
                            <Label for={`optionTextNew${index}`} className="col-sm-1 col-form-label">Metin</Label>
                            <div className="col-sm-11" style={formInput}>
                              <Input
                                name={`optionTextNew${index}`}
                                id={`optionTextNew${index}`}
                                className="form-control"
                                type="textarea"
                                style={{height: "200px"}}
                                value={useOptionsTexts[index] ? useOptionsTexts[index] : ""}
                                onChange={(e) => handleOptionsText(index, e.target.value)}
                              />
                            </div>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
              ))}
            </div>
          </div>

          <div className="form-group row mb-2">
            <Label for="chapter" className="col-sm-1 col-form-label">Doğru Cevap</Label>
            {useIsFormingSentencesQuestionNew ? (
              <div className="col-sm-11" style={formInput}>
                <Row>
                  {useOptions.map((option, index) => (
                    <Col>
                      <Label for={`correctAnswer${index}`} className="col-sm-1 col-form-label">{index + 1}</Label>
                      <div className="col-sm-11" style={formInput}>
                        <Input
                          name={`correctAnswer${index}`}
                          id={`correctAnswer${index}`}
                          className="form-control"
                          type="select"
                          value={useIsFormingSentencesQuestionNewCorrectAnswers[index]}
                          onChange={(e) => handleIsFormingSentencesQuestionNewCorrectAnswers(index, e.target.value)}
                        >
                          {useOptions.map((option, index) => (
                            <option value={index}>{index + 1} - {useOptionsTexts[index]}</option>
                          ))}
                        </Input>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              <div className="col-sm-11" style={formInput}>
                <FormGroup check inline>
                  <Input
                    id="correctAnswerHasIndex"
                    name="correctAnswerHasIndex"
                    className="form-control checkbox-input"
                    type="checkbox"
                    checked={useCorrectAnswerHasIndex}
                    onChange={(e) => setCorrectAnswerHasIndex(e.target.checked)}
                  />
                  <Label for="correctAnswerHasIndex" className="col-form-label checkbox-label">Index</Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    id="correctAnswerHasText"
                    name="correctAnswerHasText"
                    className="form-control checkbox-input"
                    type="checkbox"
                    checked={useCorrectAnswerHasText}
                    onChange={(e) => setCorrectAnswerHasText(e.target.checked)}
                  />
                  <Label for="correctAnswerHasText" className="col-form-label checkbox-label">Metin</Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    id="correctAnswerHasFile"
                    name="correctAnswerHasFile"
                    className="form-control checkbox-input"
                    type="checkbox"
                    checked={useCorrectAnswerHasFile}
                    onChange={(e) => setCorrectAnswerHasFile(e.target.checked)}
                  />
                  <Label for="correctAnswerHasFile" className="col-form-label checkbox-label">Dosya</Label>
                </FormGroup>
                <FormGroup check inline>
                  <Input
                    id="correctAnswerHasVoiceNew"
                    name="correctAnswerHasVoiceNew"
                    className="form-control checkbox-input"
                    type="checkbox"
                    checked={useCorrectAnswerHasVoiceNew}
                    onChange={(e) => setCorrectAnswerHasVoiceNew(e.target.checked)}
                  />
                  <Label for="correctAnswerHasFile" className="col-form-label checkbox-label">Ses</Label>
                </FormGroup>
                {useCorrectAnswerHasIndex && (
                  <Row>
                    <Col>
                      <Label for="correctAnswerIndex" className="col-sm-1 col-form-label">Index</Label>
                      <Row>
                        <div className="col-sm-9" style={formInput}>
                          <Input
                            name="correctAnswerIndex"
                            id="correctAnswerIndex"
                            className="form-control"
                            type="select"
                            target={useCorrectAnswerIndex ? useCorrectAnswerIndex : null}
                            onChange={(e) => setCorrectAnswerIndex(e.target.value)}
                          >
                            {useOptions.map((option, index) => (
                              <option value={index}>{index + 1}</option>
                            ))}
                          </Input>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                )}
                {useCorrectAnswerHasText && (
                  <Row>
                    <Col>
                      <Label for="correctAnswerText" className="col-sm-1 col-form-label">
                        Metinler
                        <Button style={{marginLeft:"5px", cursor:'pointer', }} color="primary" size="sm" onClick={addCorrectAnswerText}>+</Button>
                      </Label>
                      <Row>
                        <Col>
                          {useCorrectAnswerTexts.map((text, index) => (
                            <Row style={{margin: "10px 0"}}>
                              <div key={index} className="col-sm-8">
                                <Input
                                  name={`correctAnswerText${index}`}
                                  id={`correctAnswerText${index}`}
                                  type="text"
                                  value={text}
                                  className=""
                                  onChange={(e) => handleCorrectAnswerTextChange(index, e.target.value)}
                                />
                              </div>
                              <div className="col-sm-1">
                                <Button color="danger" onClick={() => removeCorrectAnswerText(index)}>
                                  <i className="mdi mdi-trash-can"></i>
                                </Button>
                              </div>
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  // <Row>
                  //   <Col>
                  //     <Label for="correctAnswerText" className="col-sm-1 col-form-label">Metin</Label>
                  //     <div className="col-sm-11" style={formInput}>
                  //       <Input
                  //         name="correctAnswerText"
                  //         id="correctAnswerText"
                  //         className="form-control"
                  //         type="textarea"
                  //         style={{height: "200px"}}
                  //         value={useCorrectAnswerText ? useCorrectAnswerText : ""}
                  //         onChange={(e) => setCorrectAnswerText(e.target.value)}
                  //       />
                  //     </div>
                  //   </Col>
                  // </Row>
                )}
                {useCorrectAnswerHasFile && (
                  <Row>
                    <Col>
                      <Label for="correctAnswerFile" className="col-sm-1 col-form-label">Dosya</Label>
                      <Row>
                        <div className="col-sm-9" style={formInput}>
                          <Input
                            name="correctAnswerFile"
                            id="correctAnswerFile"
                            className="form-control"
                            type="file"
                            target={useCorrectAnswerFile ? useCorrectAnswerFile : null}
                            onChange={(e) => setCorrectAnswerFile(e.target)}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                )}
                {useCorrectAnswerHasVoiceNew && (
                  <Row>
                    <Col>
                      <Label for="correctAnswerVoiceNew" className="col-sm-1 col-form-label">Ses</Label>
                      <div className="col-sm-11" style={formInput}>
                        <Row>
                          <div className="col-sm-5">
                            <Input
                              name="correctAnswerVoiceTextNew"
                              id="correctAnswerVoiceTextNew"
                              className="form-control"
                              type="text"
                              placeholder="Seslendirilecek Metin"
                              onChange={(e) => setCorrectAnswerVoiceTextNew(e.target.value)}
                            />
                          </div>
                          <div className="col-sm-1">
                            <Button color="primary" onClick={handleCorrectAnswerVoiceTextNew}>Seslendir</Button>
                          </div>
                          {useCorrectAnswerVoiceURLNew && useCorrectAnswerVoiceURLNew != "" && (
                            <div className="col-sm-2">
                              <audio
                                controls
                                src={useCorrectAnswerVoiceURLNew}
                                style={{height: "40px"}}
                              />
                            </div>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            )}

          </div>

        </form>
      </XModal>
      <XModal title="Görüntüle" itemTitleValue={useActiveViewItem?.name} showModal={useViewModal} toggle={toggleViewModal} saveButton={false}>
        <div className="form-group row mb-2">
          <Label for="lesson" className="col-sm-3 col-form-label">Ders</Label>
          <div className="col-sm-9">
            <Input id="lesson" className="form-control" type="text" value={useActiveViewItem["lesson"]?.name} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="topic" className="col-sm-3 col-form-label">Konu</Label>
          <div className="col-sm-9">
            <Input id="topic" className="form-control" type="text" value={useActiveViewItem["topics"]?.name || "-"} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="chapter" className="col-sm-3 col-form-label">Bölüm</Label>
          <div className="col-sm-9">
            <Input id="chapter" className="form-control" type="text" value={useActiveViewItem["chapter"]?.name || "-"} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="point" className="col-sm-3 col-form-label">Puan</Label>
          <div className="col-sm-9">
            <Input id="point" className="form-control" type="text" value={useActiveViewItem["point"] || 0} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="questionType" className="col-sm-3 col-form-label">Alıştırma Tipi</Label>
          <div className="col-sm-9">
            <Input id="questionType" className="form-control" type="text" value={useActiveViewItem["questionType"] || '-'} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="question" className="col-sm-3 col-form-label">Alıştırma</Label>
          <div className="col-sm-9">
            {useActiveViewItem?.question?.imageFiles && useActiveViewItem?.question?.imageFiles.length > 0 && (
              <>
              {imageFromURL(useActiveViewItem?.question?.imageFiles[0], {width: 50, height: 50}, {height:"50px", width:"auto"} )}
              <br />
              </>
            )}
            {useActiveViewItem?.question?.texts && useActiveViewItem?.question?.texts.length > 0 && (
              <>
              {useActiveViewItem?.question?.texts[0]}
              <br />
              </>
            )}
            {useActiveViewItem?.question?.voiceFiles && useActiveViewItem?.question?.voiceFiles.length > 0 && (
              <>
              <audio controls src={audioURL(useActiveViewItem?.question?.voiceFiles[0])} style={{height: "40px"}} />
              <br />
              </>
            )}
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="options" className="col-sm-3 col-form-label">Seçenekler</Label>
          <div className="col-sm-9">
            {useActiveViewItem?.options && useActiveViewItem?.options.length > 0 && (
              useActiveViewItem?.options.map((option, index) => (
                <div>
                  {option?.valueImageFile && (
                    <>
                    {imageFromURL(option?.valueImageFile, {width: 50, height: 50}, {height:"50px", width:"auto"} )}
                    <br />
                    </>
                  )}
                  {option?.value && (
                    <>
                    {option?.value}
                    <br />
                    </>
                  )}
                  {option?.valueVoiceFile && (
                    <>
                    <audio controls src={audioURL(option?.valueVoiceFile)} style={{height: "40px"}} />
                    <br />
                    </>
                  )}
                </div>
              ))
            )}
              
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="correctAnswer" className="col-sm-3 col-form-label">Doğru Cevap</Label>
          {useActiveViewItem?.questionType == "formingSentences" ? (
            <div className="col-sm-9">
              {useActiveViewItem?.correctAnswer?.customValue?.answer && (
                <>
                {useActiveViewItem?.correctAnswer?.customValue?.answer.map((answer, index) => (
                  <div>
                    {index + 1}. {useActiveViewItem?.options[answer]?.value}
                  </div>
                ))}
                </>
              )}
            </div>
          ) : (
            <div className="col-sm-9">
              {(useActiveViewItem?.correctAnswer?.index || useActiveViewItem?.correctAnswer?.index >= 0) && (
                <>
                Index: {useActiveViewItem?.correctAnswer?.index}
                <br />
                </>
              )}
              {useActiveViewItem?.correctAnswer?.text && (
                <>
                Metin: {useActiveViewItem?.correctAnswer?.text}
                <br />
                </>
              )}
              {useActiveViewItem?.correctAnswer?.texts && (
                <>
                Metinler: {useActiveViewItem?.correctAnswer?.texts.join(", ")}
                <br />
                </>
              )}
              {useActiveViewItem?.correctAnswer?.file && (
                <>
                Dosya: 
                {imageFromURL(useActiveViewItem?.correctAnswer?.file, {width: 50, height: 50}, {height:"50px", width:"auto"} )}
                <br />
                </>
              )}
              {useActiveViewItem?.correctAnswer?.voiceFile && (
                <>
                Ses: 
                <audio controls src={audioURL(useActiveViewItem?.correctAnswer?.voiceFile)} style={{height: "40px"}} />
                <br />
                </>
              )}
            </div>
          )}
        </div>

        
      </XModal>
      <XModal 
        title="Sil" 
        itemTitleValue={useActiveDeleteItem?.name} 
        showModal={useDeleteModal} 
        toggle={toggleDeleteModal} 
        saveButton="Evet"
        onSubmit={()=>{handleDelete(useActiveDeleteItem);}}
      >
        {`${useActiveDeleteItem?.name}`}'i silmek istediğinize emin misiniz?
      </XModal>
      <XModal
        title="Toplu Ekle"
        showModal={useMultiModal}
        toggle={toggleMultiModal}
        saveButton="Gönder"
        onSubmit={()=>{}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="multiFile" className="col-sm-3 col-form-label">Dosya</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="multiFile"
                className="form-control"
                type="file"
                multiple={true}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
        </form>
      </XModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    title: state.Layout.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBreadCrumbs: (title, breadcrumb) => dispatch(setBreadcrumbItems(title, breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Exercises);
