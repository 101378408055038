import React, { useState, useCallback, useContext, createContext } from 'react'
import { Alert } from 'reactstrap';

const AlertContext = createContext()

export function AlertProvider(props) {
  const [open, setOpen] = useState(false);
  const [useMessage, setMessage] = useState("");
  const [useType, setType] = useState("success");

  const alertStyle = {
    position: "fixed",
    top: "10px",
    right: "10px",
    zIndex: 9999,
  };

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  var timeout = null;
  const handleOpen = useCallback((message, type="success") => {
    setOpen(false)
    setMessage(message)
    setType(type)
    setOpen(true)
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        setOpen(false)
        setMessage("")
    }, 2000);
  }, [setMessage, setOpen, setType])
    
  return (
    <AlertContext.Provider value={[handleOpen, handleClose]}>
      {props.children}
      <Alert style={alertStyle} color={useType} isOpen={open} toggle={handleClose} >
        {useMessage}
      </Alert>
    </AlertContext.Provider>
  )
}


export function useAlert() {
  const context = useContext(AlertContext);
  if (!context)
    throw new Error('`useAlert()` must be called inside an `AlertProvider` child.')

  return context
}