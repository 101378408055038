import React, { useEffect, useState} from "react"
import { setBreadcrumbItems } from "../../store/actions";
import { connect } from "react-redux";
import { uploadFile, Request, } from "helpers/api_helper";
import { Button, Card, CardBody, CardTitle, Col, Container, Input, Label, Row, } from "reactstrap";
import XModal from "components/Custom/XModal";
import { slugify } from "helpers/helpers";
import ActionsCol from "components/Custom/ActionsCol";
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Select from 'react-select';
import { get } from "helpers/api_helper";
import { useAlert } from "providers/alertProvider";

const accessActionRoles = ['admin', 'editor', 'manager'];

const dataCols = [
  {
    dataField: "firstName",
    text: "İsim",
    type: "text",
    required: true,
    filter: textFilter(),
  },
  {
    dataField: "lastName",
    text: "Soyisim",
    type: "text",
    required: false,
    filter: textFilter(),
  },
  {
    dataField: "email",
    text: "E-Posta",
    type: "text",
    required: true,
    filter: textFilter(),
  },
  {
    dataField: "school",
    text: "Okul",
    type: "text",
    required: true,
    filter: textFilter(),
  },
  {
    dataField: "authorizedClasses",
    text: "Sınıflar",
    type: "select",
    required: false,
    filter: textFilter(),
  },
  {
    dataField: "age",
    text: "Yaş",
    type: "number",
    required: false,
    // filter: textFilter(),
  },
  {
    dataField: "gender",
    text: "Cinsiyet",
    type: "select",
    required: false,
    filter: textFilter(),
  },
  
];

const cols = [
  {
    dataField: "num",
    text: "#",
    type: false
  },
  ...dataCols,
  {
    dataField: "actions",
    text: "İşlemler",
    type: false,
  }
];

const formInput = {
  marginTop: "0.5rem",
};

const url = "profile";
const schoolUserUrl = "schoolUser";
const mySchoolUrl = "school/mySchool";
const pageTitle = "Öğretmenler";
const modelSingularName = "Öğretmen";
const modelPluralName = "Öğretmenler";
const breadcrumbItems = [
  { title: "Anasayfa", url: "/" },
  { title: "Öğretmenler", url: "/ogretmenler" },
];

const genders = [
  { value: 'male', label: 'Erkek' },
  { value: 'female', label: 'Kadın' },
];

const Teachers = props => {
  document.title = props.title || "öğretmenler";
  useEffect(() => {
    props.onSetBreadCrumbs(modelPluralName, breadcrumbItems);
  }, []);

  //! Core Codes
  const [useDataTableData, setDataTableData] = useState([]);
  const [useTableData, setTableData] = useState([]);
  const [useViewModal, setViewModal] = useState(false);
  const [useEditModal, setEditModal] = useState(false);
  const [useDeleteModal, setDeleteModal] = useState(false);
  const [useNewModal, setNewModal] = useState(false);
  const [useMultiModal, setMultiModal] = useState(false);
  const [useActiveViewItem, setActiveViewItem] = useState({});
  const [useActiveEditItem, setActiveEditItem] = useState({});
  const [useActiveDeleteItem, setActiveDeleteItem] = useState({});
  const [useActiveEditItemName, setActiveEditItemName] = useState('');
  const [useActiveNewItem, setActiveNewItem] = useState({});
  const [useCols, setCols] = useState(cols);
  const [useAllResponse, setAllResponse] = useState({});
  const [useSchools, setSchools] = useState([]);
  const [useSchoolClasses, setSchoolClasses] = useState([]);
  const [useSchoolsEdit, setSchoolsEdit] = useState([]);
  const [useSchoolClassesEdit, setSchoolClassesEdit] = useState([]);
  const [useUserRole, setUserRole] = useState(null);
  const [openAlert] = useAlert();
  const [useErrorFields, setErrorFields] = useState([]);
  
  const hasRoleAccess = (role) => accessActionRoles.includes(role);

  const toggleViewModal = (item) => {
    setViewModal(!useViewModal);
    item ? setActiveViewItem(item) : setActiveViewItem({});
  };
  const toggleEditModal = (item) => {
    if(item) {
      setErrorFields([]);
      setActiveEditItem(item);
      setActiveEditItemName(item.title);
      setEditModal(true);
    } else {
      setEditModal(false);
    }
  }
  const toggleDeleteModal = (item) => {
    if(item) {
      setActiveDeleteItem(item);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
    }
  }
  const toggleNewModal = () => {
    setErrorFields([]);
    setNewModal(!useNewModal);
  }
  const toggleMultiModal = () => {
    setErrorFields([]);
    setMultiModal(!useMultiModal);
  }
  
  const validateFields = (activeItem) => {
    const errors = []; 
    for (let dataCol of dataCols) {
        if (dataCol.required && !activeItem[dataCol.dataField]) {
          errors.push(dataCol.dataField);
        } 
    }
    setErrorFields(errors); 
    return errors.length === 0;  
  };

  const handleEditItemChange = (e) => {

    if(e.target.type === "file") {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target,
      });
    } else {
      setActiveEditItem({
        ...useActiveEditItem,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleNewItemChange = (e) => {
    let value = e.target;
    if(e.target.type !== "file") {
      value = value.value
    } 

    setActiveNewItem({
      ...useActiveNewItem,
      [e.target.name]: value,
    });
  };

  const handleEdit = (activeItem) => {
    // Check if the image typed fields in the form is set
    const deletedFields = [];
    const fileUploadPromises = [];
    for (let dataCol of dataCols.filter((dataCol) => dataCol.isForeign !== true)) {
      if (dataCol.type === "image" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "image" && activeItem[dataCol.dataField]) {
        if(activeItem[dataCol.dataField]?.files?.length > 0) {
          const file = activeItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            activeItem[dataCol.dataField] = fileId;
          }));
        }
      }
      if (dataCol.type === "file" && !activeItem[dataCol.dataField]) {
        deletedFields.push({[dataCol.dataField]: activeItem[dataCol.dataField]});
        delete activeItem[dataCol.dataField];
      } else if(dataCol.type === "file" && activeItem[dataCol.dataField]) {
        if(activeItem[dataCol.dataField]?.files?.length > 0) {
          const file = activeItem[dataCol.dataField].files[0];
          fileUploadPromises.push(uploadFile(file).then((response) => {
            const fileId = response._id;
            activeItem[dataCol.dataField] = fileId;
          }));
        }
      }
      if (dataCol.readonly && dataCol.dataField === "slug") {
        activeItem.slug = slugify(activeItem.name);
        delete activeItem.slug;
      }
    }
    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveEditItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveEditItem[key];
        }
      });
      const { _id, ...updatedItem } = activeItem;
      Request.put(`/${schoolUserUrl}/${_id}`, updatedItem)
      .then(response => {
        if (response) {
          openAlert(`${modelSingularName} bilgileri güncellendi`);
        } else {
          openAlert("İşlem başarısız, lütfen tekrar deneyin.", "danger");
        }

        return Request.get(`/${url}/myTeachers`);
      })
      .then(response => response.data)
      .then(data => {
        fetchEntire();
      })
      .then(() => {
        toggleEditModal();
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
    });
  };

  useEffect(() => {
    if (!useEditModal) {
      setActiveEditItem({});
    }
  }, [useEditModal]);

  const handleNew = () => {
    if (!validateFields(useActiveNewItem)) {
      return; 
    }
    const fileUploadPromises = [];
    for (let dataCol of dataCols) {
      if (dataCol.type === "image" && useActiveNewItem[dataCol.dataField]) {
        const file = useActiveNewItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(file).then((response) => {
          const fileId = response._id;
          useActiveNewItem[dataCol.dataField] = fileId;
        }));
      } else if(dataCol.type === "file" && useActiveNewItem[dataCol.dataField]) {
        const file = useActiveNewItem[dataCol.dataField].files[0];
        fileUploadPromises.push(uploadFile(file).then((response) => {
          const fileId = response._id;
          useActiveNewItem[dataCol.dataField] = fileId;
        }));
      }
    }
    Promise.all(fileUploadPromises).then(() => {
      Object.entries(useActiveNewItem).forEach(([key, value]) => {
        if (value === "") {
          delete useActiveNewItem[key];
        }
      });
      Request.post(`/${schoolUserUrl}/createTeacher`, useActiveNewItem)
      .then(response => {
        if (response) {
          openAlert(`Yeni ${modelSingularName} oluşturuldu`);
        } else {
          openAlert("İşlem başarısız, lütfen tekrar deneyin.", "danger");
        }

        return Request.get(`/${url}/myTeachers`);
      })
      .then(response => response.data)
      .then(data => {
        fetchEntire();
      })
      .then(() => {
        toggleNewModal();
        setActiveNewItem({});
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
    });
  };

  const handleDelete = (activeItem) => {
    Request.del(`/${schoolUserUrl}/${activeItem._id}`)
      .then(response => {
        if (response) {
          openAlert(`${modelSingularName} silindi`);
        } else {
          openAlert("İşlem başarısız, lütfen tekrar deneyin.", "danger");
        }
  
        return Request.get(`/${url}/myTeachers`);
      })
      .then(response => response.data)
      .then(data => {
        fetchEntire();
      })
      .then(() => {
        toggleDeleteModal();
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
  };  

  useEffect(() => {
    if (!useDeleteModal) {
      setActiveDeleteItem({});
    }
  }, [useDeleteModal]);

  const fetchTableData = (data, role) => {
    if(data &&  data.length > 0 && Array.isArray(data)) {
      const mapped = data.map((item, index) => {
        return {
          num: index + 1,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item?.user?.email,
          school: item?.school?.name,
          authorizedClasses: item?.authorizedClasses?.length > 0 
          ? item.authorizedClasses.map(classItem => classItem.name).join(' | ')
          : "Sınıf bulunmamaktadır",

          age: item.age,
          gender: genders.find(gender=>gender.value == item.gender)?.label,
          actions: 
            <ActionsCol 
              id={item._id} 
              onView={() => { toggleViewModal(item) }}
              onEdit={hasRoleAccess(role) ? () => toggleEditModal(item) : null}
              onDelete={hasRoleAccess(role) ? () => toggleDeleteModal(item) : null}
            />,
        }
      });
      return mapped;
    }
    return [];
  };

  const getUserRole = async () => {
    const userResponse = await get("/user/me");
    const role = userResponse.role;
    setUserRole(role);
    return role;
  };

  const fetchEntire = async () => {
    const role = await getUserRole();
    
    Request.get(`/${url}/myTeachers`)
    .then(response => {
      const data = response.data;
      setDataTableData(data);
      setAllResponse(data);
      const fetchedTableData = fetchTableData(data, role);
      setTableData(fetchedTableData);
      
      return Request.get(`${mySchoolUrl}`);
    })
    .then(schoolResponse => {
      
      setSchools(schoolResponse.data);
      setSchoolsEdit(schoolResponse.data);
      
      if (useSchools[0]) {
        const firstSchool = useSchools[0]._id;
        Request.get(`/schoolClass/bySchool/${firstSchool}`)
          .then(response => {
            setSchoolClasses(response.data);
          });
      }
    })
    .catch(() => {
      openAlert("Bir hata oluştu. Lütfen tekrar deneyins.", "danger");
    });
  };

  useEffect(() => {
    fetchEntire();
  }, []);

  useEffect(() => {
    if(useActiveNewItem["school"]) {
      Request.get(`/schoolClass/bySchool/${useActiveNewItem?.school}`)
      .then(response => {
        setSchoolClasses(response.data);
      })
      .catch(() => {
        openAlert("Bir hata oluştu. Lütfen tekrar deneyin.", "danger");
      });
    }
    else{
      setSchoolClasses([]);
    }
  }, [useActiveNewItem["school"]]);
    
  
  useEffect(() => {
    if(useActiveEditItem["school"]) {
      if(useActiveEditItem?.school?._id) {
        Request.get(`/schoolClass/bySchool/${useActiveEditItem?.school?._id}`)
        .then(response => {
          setSchoolClassesEdit(response.data);
        });
      } else {
        Request.get(`/schoolClass/bySchool/${useActiveEditItem?.school}`)
        .then(response => {
          setSchoolClassesEdit(response.data);
        });
      }
    } else{
      setSchoolClassesEdit([]);
      setActiveEditItem(prevState => ({
        ...prevState,
        authorizedClasses: [] 
      }));
    }
  }, [useActiveEditItem['school']]);
  //! End Core Codes

  return (
    // <DataTable dataCols={dataCols} cols={cols} url={url} modelPluralName={modelPluralName} pageTitle={pageTitle} />
    <React.Fragment>
      <div className="page-content p-0">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {pageTitle}
                    {
                      (hasRoleAccess(useUserRole) ) && (
                        <Button color="success" style={{ marginLeft: "1rem" }} onClick={toggleNewModal}>
                          Yeni Ekle
                        </Button>
                      )
                    }
                  </CardTitle>
                  Toplam {useAllResponse?.length} {modelSingularName} bulunmaktadır.
                  <div className="table-responsive">
                    <BootstrapTable
                      keyField="num"
                      data={useTableData}
                      columns={useCols}
                      filter={filterFactory()}
                      filterPosition="inline"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      

      <XModal 
        title="Yeni Ekle" 
        showModal={useNewModal}
        toggle={toggleNewModal}
        onSubmit={()=>{handleNew();}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="firstName" className="col-sm-3 col-form-label">İsim</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="firstName"
                id="firstName"
                className={`form-control ${useErrorFields.includes('firstName') ? 'is-invalid' : ''}`}
                type="text"
                required={true}
                value={useActiveNewItem["firstName"] ? useActiveNewItem["firstName"] : ""}
                onChange={handleNewItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="lastName" className="col-sm-3 col-form-label">Soyisim</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="lastName"
                id="lastName"
                className="form-control"
                type="text"
                required={true}
                value={useActiveNewItem["lastName"] ? useActiveNewItem["lastName"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="email" className="col-sm-3 col-form-label">E-Posta</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="email"
                id="email"
                className={`form-control ${useErrorFields.includes('email') ? 'is-invalid' : ''}`}
                type="text"
                required={true}
                value={useActiveNewItem["email"] ? useActiveNewItem["email"] : ""}
                onChange={handleNewItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="password" className="col-sm-3 col-form-label">Parola</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="password"
                id="password"
                className={`form-control ${useErrorFields.includes('password') ? 'is-invalid' : ''}`}
                type="password"
                required={true}
                value={useActiveNewItem["password"] ? useActiveNewItem["password"] : ""}
                onChange={handleNewItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="school" className="col-sm-3 col-form-label">Okul</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="school"
                id="school"
                className={`form-control ${useErrorFields.includes('school') ? 'is-invalid' : ''}`}
                type="select"
                required={true}
                value={useActiveNewItem["school"] ? useActiveNewItem["school"] : ""}
                onChange={handleNewItemChange}
              >
                <option value="">Seçiniz</option>
                {useSchools.map((school, index) => {
                  return <option key={index} value={school._id}>{school.name}</option>
                })}
              </Input>
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="schoolClass" className="col-sm-3 col-form-label">Sınıf</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="schoolClass"
                id="schoolClass"
                className={`form-control ${useErrorFields.includes('authorizedClasses') ? 'is-invalid' : ''}`}
                type="select"
                required={true}
                value={useActiveNewItem["schoolClass"] ? useActiveNewItem["schoolClass"] : ""}
                onChange={handleNewItemChange}
              >
                <option value="">Seçiniz</option>
                {useSchoolClasses.map((schoolClass, index) => {
                  return <option key={index} value={schoolClass._id}>{schoolClass.name}</option>
                })}
              </Input> 
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="age" className="col-sm-3 col-form-label">Yaş</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="age"
                id="age"
                className="form-control"
                type="number"
                required={false}
                value={useActiveNewItem["age"] ? useActiveNewItem["age"] : ""}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="gender" className="col-sm-3 col-form-label">Cinsiyet</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="gender"
                id="gender"
                className="form-control"
                type="select"
                required={false}
                value={useActiveNewItem["gender"] ? useActiveNewItem["gender"] : ""}
                onChange={handleNewItemChange}
              >
                {Object.entries(genders).map(([key, value]) => {
                  return <option key={key} value={value.value}>{value.label}</option>
                })}
              </Input>
            </div>
          </div>

          
        </form>
      </XModal>
      <XModal title="Görüntüle" itemTitleValue={useActiveViewItem?.name} showModal={useViewModal} toggle={toggleViewModal} saveButton={false}>
        <div className="form-group row mb-2">
          <Label for="firstName" className="col-sm-3 col-form-label">İsim</Label>
          <div className="col-sm-9">
            <Input id="firstName" className="form-control" type="text" value={useActiveViewItem["firstName"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="lastName" className="col-sm-3 col-form-label">Soyisim</Label>
          <div className="col-sm-9">
            <Input id="lastName" className="form-control" type="text" value={useActiveViewItem["lastName"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="email" className="col-sm-3 col-form-label">E-Posta</Label>
          <div className="col-sm-9">
            <Input id="email" className="form-control" type="text" value={useActiveViewItem?.user?.email} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="school" className="col-sm-3 col-form-label">Okul</Label>
          <div className="col-sm-9">
            <Input id="school" className="form-control" type="text" value={useActiveViewItem?.school?.name} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="schoolClass" className="col-sm-3 col-form-label">Sınıflar</Label>
          <div className="col-sm-9">
            <Input id="schoolClass" className="form-control" type="text" 
              value={useActiveViewItem?.authorizedClasses?.length > 0 
                ? useActiveViewItem.authorizedClasses.map(classItem => classItem.name).join(', ') 
                : "Sınıf bulunmamaktadır"} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="age" className="col-sm-3 col-form-label">Yaş</Label>
          <div className="col-sm-9">
            <Input id="age" className="form-control" type="text" value={useActiveViewItem["age"]} readOnly />
          </div>
        </div>
        <div className="form-group row mb-2">
          <Label for="gender" className="col-sm-3 col-form-label">Cinsiyet</Label>
          <div className="col-sm-9">
            <Input id="gender" className="form-control" type="text" value={genders.find(gender=>gender.value==useActiveViewItem?.gender)?.label} readOnly />
          </div>
        </div>

        
      </XModal>
      <XModal 
        title="Düzenle" 
        itemTitleValue={useActiveEditItemName} 
        showModal={useEditModal} 
        toggle={toggleEditModal}
        onSubmit={()=>{handleEdit(useActiveEditItem); }}
      >
        <form>
          
          <div className="form-group row mb-2">
            <Label for="firstName" className="col-sm-3 col-form-label">İsim</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="firstName"
                id="firstName"
                className={`form-control ${useErrorFields.includes('firstName') ? 'is-invalid' : ''}`}
                type="text"
                required={true}
                value={useActiveEditItem["firstName"] ? useActiveEditItem["firstName"] : ""}
                onChange={handleEditItemChange}
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="lastName" className="col-sm-3 col-form-label">Soyisim</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="lastName"
                id="lastName"
                className="form-control"
                type="text"
                required={true}
                value={useActiveEditItem["lastName"] ? useActiveEditItem["lastName"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="email" className="col-sm-3 col-form-label">E-Posta</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="email"
                id="email"
                className="form-control"
                type="text"
                value={useActiveEditItem?.user?.email ? useActiveEditItem?.user?.email : ""}
                onChange={handleEditItemChange}
                readOnly
              />
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="school" className="col-sm-3 col-form-label">Okul</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="school"
                id="school"
                className={`form-control ${useErrorFields.includes('school') ? 'is-invalid' : ''}`}
                type="select"
                required={true}
                value={
                  useActiveEditItem?.school?._id ? useActiveEditItem?.school?._id : 
                  useActiveEditItem?.school ? useActiveEditItem?.school : ""
                }
                onChange={handleEditItemChange}
              >
                <option value="">Seçiniz</option>
                {useSchoolsEdit.map((school, index) => {
                  return <option key={index} value={school._id}>{school.name}</option>
                })}
              </Input>
              <div className="invalid-feedback">Bu alan gereklidir.</div>
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="schoolClass" className="col-sm-3 col-form-label">Yetkili sınıflar</Label>            
            <div className="col-sm-9" style={formInput}>
            <Select
              isMulti
              name="schoolClass"
              value={useActiveEditItem?.authorizedClasses?.map((classItem) => ({
                value: classItem._id,
                label: classItem.name
              })) || []}
              options={useSchoolClassesEdit
                .filter((schoolClass) => 
                  !useActiveEditItem?.authorizedClasses?.some(
                    (classItem) => classItem._id === schoolClass._id
                  )
                )
                .map((schoolClass) => ({
                  value: schoolClass._id,
                  label: schoolClass.name
                }))}
              onChange={(selectedOptions) => {
                setActiveEditItem(prevState => ({
                  ...prevState,
                    authorizedClasses: selectedOptions.map(option => ({
                    _id: option.value,
                    name: option.label
                  }))
                }));
              }}
            />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="age" className="col-sm-3 col-form-label">Yaş</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="age"
                id="age"
                className="form-control"
                type="number"
                required={false}
                value={useActiveEditItem["age"] ? useActiveEditItem["age"] : ""}
                onChange={handleEditItemChange}
              />
            </div>
          </div>
          <div className="form-group row mb-2">
            <Label for="gender" className="col-sm-3 col-form-label">Cinsiyet</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="gender"
                id="gender"
                className="form-control"
                type="select"
                required={false}
                value={useActiveEditItem["gender"]}
                onChange={handleEditItemChange}
              >
                {Object.entries(genders).map(([key, value]) => {
                  return <option key={key} value={value.value}>{value.label}</option>
                })}
              </Input>
            </div>
          </div>


          
        </form>
      </XModal>
      <XModal 
        title="Sil" 
        itemTitleValue={useActiveDeleteItem?.title} 
        showModal={useDeleteModal} 
        toggle={toggleDeleteModal} 
        saveButton="Evet"
        onSubmit={()=>{handleDelete(useActiveDeleteItem);}}
      >
        {`${useActiveDeleteItem?.firstName} ${useActiveDeleteItem?.lastName}`}'i silmek istediğinize emin misiniz?
      </XModal>
      <XModal
        title="Toplu Ekle"
        showModal={useMultiModal}
        toggle={toggleMultiModal}
        saveButton="Gönder"
        onSubmit={()=>{}}
      >
        <form>
          <div className="form-group row mb-2">
            <Label for="multiFile" className="col-sm-3 col-form-label">Dosya</Label>
            <div className="col-sm-9" style={formInput}>
              <Input
                name="multiFile"
                className="form-control"
                type="file"
                multiple={true}
                onChange={handleNewItemChange}
              />
            </div>
          </div>
        </form>
      </XModal>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    title: state.Layout.title,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBreadCrumbs: (title, breadcrumb) => dispatch(setBreadcrumbItems(title, breadcrumb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
